import React, { useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { LoggedInContext } from '../context';
import chromeIcon from '../assets/images/chromeIcon.svg';
import '../styles/ChromePrompt.scss';

const ChromePrompt = () => {
  const { userSettings } = useContext(LoggedInContext);
  if (userSettings!.isChromePromptDismissed) return null;

  const dismissPrompt = () =>
    firebase.firestore().collection('userSettings').doc(userSettings!.user).update({ isChromePromptDismissed: true });

  return (
    <div className="chromePrompt">
      <h2>Use Confetti on Chrome</h2>
      <a
        href="https://chrome.google.com/webstore/detail/hicbenbldlkghoodigniooplopgjjpnh/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <button type="button" className="chromePrompt__addButton">
          <img src={chromeIcon} alt="Google Chrome Icon" />
          Add to Chrome
        </button>
      </a>
      <button type="button" className="chromePrompt__dismissButton" onClick={dismissPrompt}>
        Dismiss
      </button>
    </div>
  );
};

export default ChromePrompt;
