import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: 'https://0cacb485e82047d4b3d6811412b08362@sentry.io/1867676' });
}

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register();
