import React, { useContext } from 'react';
import { format } from 'date-fns';

import TaskHelper from '../services/TaskHelper';
import useRefresher from '../hooks/useRefresher';
import { LoggedInContext } from '../context';
import DateSelectors from '../components/DateSelectors';
import ViewSelector from '../components/ViewSelector';
import OverallProgressBar from '../components/OverallProgressBar';
import DashboardProgressBar from '../components/DashboardProgressBar';
import '../styles/AllTime.scss';

const AllTime = () => {
  const { tasks, userSettings } = useContext(LoggedInContext);
  const { mounted, refreshState } = useRefresher();
  const allTimeGoalPercentage = TaskHelper.allTimeGoalPercentage(tasks);

  return (
    <div className="allTime">
      <div className="main-top">
        <DateSelectors backInactive forwardInactive />
        <h1 className="main-top-header">{format(userSettings.createdAt.toDate(), 'PP')} - Today</h1>
        <div className="main-top__left">
          <ViewSelector summaryViewOnly refreshState={refreshState} />
        </div>
      </div>
      <OverallProgressBar width={allTimeGoalPercentage} timePeriod="allTime" progressComparison={0} />
      <div className="allTime__main">
        {TaskHelper.orderEntries(tasks).map(([taskId, task]) => {
          const { numerator, denominator } = TaskHelper.alltimeStats(task);
          const text = `${format(task.dateCreated.toDate(), 'PP')} - Today`;
          return (
            <DashboardProgressBar
              key={taskId}
              task={task}
              text={text}
              numerator={numerator}
              denominator={denominator}
              mounted={mounted}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AllTime;
