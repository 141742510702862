import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';

import IndexHeader from '../components/IndexHeader';
import Footer from '../components/Footer';
import IndexAnimation from '../components/IndexAnimation';
import apiClient from '../services/apiClient';
import Circle from '../assets/images/circle.svg';
import StreakIcon from '../assets/images/streakIconRed.svg';

import X from '../assets/images/X.svg';
import chromeIcon from '../assets/images/chromeIcon.svg';
import indexHero from '../assets/images/indexHero.svg';
import indexiOSButton from '../assets/images/indexiOSButton.svg';
import indexGooglePlayButton from '../assets/images/indexGooglePlayButton.svg';
import indexDesktop from '../assets/images/indexDesktop.png';
import indexMobileFrame from '../assets/images/indexMobileFrame.png';
import indexMobileVideoBackup from '../assets/images/indexMobileVideoBackup.png';
import indexSection2Image from '../assets/images/indexSection2Image.svg';
import indexToDo from '../assets/images/indexToDo.svg';
import indexNotToDo from '../assets/images/indexNotToDo.svg';
import indexStreaks from '../assets/images/indexStreaks.svg';
import indexProgress from '../assets/images/indexProgress.svg';
import indexProgressIcon from '../assets/images/indexProgressIcon.svg';
import indexSection5Image from '../assets/images/indexSection5Image.svg';
import ProgressBar from '../assets/lottie/progressBar.json';
import '../styles/Index.scss';

const Index = () => {
  const [prices, setPrices] = useState({ month: 0, year: 0 });

  useEffect(() => {
    const fetchPrices = async () => {
      const { data } = await apiClient.getPrices();
      setPrices(data);
    };
    fetchPrices();
  }, []);

  return (
    <div className="index">
      <IndexHeader />
      <div className="index__container">
        {/* HERO SECTION */}
        <div className="index-hero index-wrapper">
          <div className="index-hero__container index-container">
            <div className="index-hero__text">
              <img src={indexHero} alt="Hero" />
              <h1>A habit tracker for celebrating daily goals</h1>
              <h2>Develop good habits and eliminate bad ones by visualizing daily progress</h2>
            </div>
            <div className="index-hero__buttons index-buttons">
              <a href="https://apps.apple.com/us/app/confetti-habits/id1507853418">
                <img className="index__appStoreButton" src={indexiOSButton} alt="iOS App Store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.confettimobile">
                <img className="index__appStoreButton" src={indexGooglePlayButton} alt="Google Play Store" />
              </a>
            </div>
          </div>
        </div>

        {/* SECTION 1 - SCREENSHOTS */}
        <div className="index-section1 index-wrapper">
          <div className="index-section1__container index-container">
            <h1>Available wherever you are</h1>
            <h2>
              Available for <a href="https://tryconfetti.io">iOS</a>, <a href="ttps://tryconfetti.io">Android</a>,{' '}
              <a href="https://chrome.google.com/webstore/detail/hicbenbldlkghoodigniooplopgjjpnh/">Chrome</a> and{' '}
              <Link to="/signup">Web</Link>
            </h2>
            <div className="index-section1__images">
              <video
                className="index-section1__imagesMobile"
                poster={indexMobileVideoBackup}
                playsInline
                autoPlay
                loop
                muted
              >
                <source src="/videos/indexMobileVideo.mp4" type="video/mp4" />
              </video>
              <img
                className="index-section1__imagesMobileFrame"
                src={indexMobileFrame}
                alt="Screenshot of mobile app"
              />
              <div className="index-section1__buttons index-buttons">
                <a href="https://apps.apple.com/us/app/confetti-habits/id1507853418">
                  <img className="index__appStoreButton" src={indexiOSButton} alt="iOS App Store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.confettimobile">
                  <img className="index__appStoreButton" src={indexGooglePlayButton} alt="Google Play Store" />
                </a>
              </div>
              <img className="index-section1__imagesDesktop" src={indexDesktop} alt="Screenshot of App" />
            </div>
            <div className="index-section1__buttons index-buttons">
              <Link to="/signup">
                <button type="button" className="index__ctaButton">
                  Try for Free
                </button>
              </Link>
              <a href="https://chrome.google.com/webstore/detail/hicbenbldlkghoodigniooplopgjjpnh/">
                <button type="button" className="index__chromeButton">
                  <img src={chromeIcon} alt="Google Chrome" />
                  Add to Chrome
                </button>
              </a>
            </div>
          </div>
        </div>

        {/* SECTION 2 - TO DO's*/}
        <div className="index-section2 index-wrapper">
          <div className="index-section2__container index-container">
            <h1>Set goals, simply</h1>

            <div className="index-section2__section">
              <img className="index-section2__sectionIcon" src={Circle} alt="A Green Circle" />
              <h3>To-Do</h3>
              <p>
                Trying to learn Spanish? Want to read for 30 minutes a day? Manage daily habits and achieve your goals.
              </p>
              <img className="progress" src={indexToDo} alt="An example list of to-dos" />
            </div>
            <div className="index-section2__section">
              <img
                className="index-section2__floating"
                src={indexSection2Image}
                alt="Person sitting with their phone"
              />
              <img className="index-section2__sectionIcon" src={X} alt="A Red X" />
              <h3>Not-To-Do</h3>
              <p>
                Spending hours on Instagram? Track what’s stopping you from achieving goals and build better daily
                habits.
              </p>
              <img className="progress" src={indexNotToDo} alt="An example list of no-to-dos" />
            </div>
            <div className="index-section2__section">
              <img className="index-section2__sectionIcon" src={StreakIcon} alt="A Fire Icon indicating a streak" />
              <h3>Streaks</h3>
              <p>Don’t break the chain! Each small win provides motivation and fuel to continue building your habit.</p>
              <img className="progress" src={indexStreaks} alt="An example of streaks" />
            </div>
            <div className="index-section2__section">
              <img
                className="index-section2__sectionIcon"
                src={indexProgressIcon}
                alt="A Fire Icon indicating a streak"
              />
              <h3>Progress</h3>
              <p>Measure your success with daily, weekly, monthly, yearly and all time progress for habits.</p>
              <img className="progress" src={indexProgress} alt="An example list of to-dos" />
            </div>
          </div>
          <div className="index-section2__buttons">
            <Link to="/signup">
              <button type="button" className="index__ctaButton-alt">
                Get Started Free
              </button>
            </Link>
          </div>
        </div>

        {/* SECTION 3 - PRICING */}
        <div className="index-pricing index-wrapper">
          <div className="index-pricing__container index-container">
            <div className="index-pricing__containerLeft">
              <h1>Pricing</h1>
              <p>Free for your first three habits or join premium for unlimited.</p>
              <Link to="/signup">
                <button type="button" className="index__ctaButton">
                  Get Started Free
                </button>
              </Link>
            </div>
            <div className="index-pricing__containerRight">
              <div className="index-pricing__card">
                <h1>
                  <span role="img" aria-label="Free">
                    🌱
                  </span>
                </h1>
                <h2>Free</h2>
                <h3>$0</h3>
                <h4>/ forever</h4>
                <ul>
                  <li>3 Free Habits</li>
                  <li>All the features</li>
                </ul>
              </div>
              <div className="index-pricing__card">
                <h1>
                  <span role="img" aria-label="Rocket">
                    🚀
                  </span>
                </h1>
                <h2>Premium</h2>
                <div className="index-pricing__cardPricing">
                  <div>
                    <h3>${(prices.month / 100).toFixed(2)}</h3>
                    <h4>/ month</h4>
                  </div>
                  <div>
                    <h3>${(prices.year / 100).toFixed(2)}</h3>
                    <h4>/ year</h4>
                  </div>
                </div>
                <ul>
                  <li>Unlimited Habits</li>
                  <li>All the features</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* SECTION 4 - ANIMATION */}
        <div className="index-animation index-wrapper">
          <div className="index-animation__container index-container">
            <h1>We are what we do every day</h1>
            <h2>No matter how you want to improve, Confetti is flexible enough to track any habit</h2>
          </div>
          <IndexAnimation />
        </div>

        {/* SECTION 5 */}
        <div className="index-section5 index-wrapper">
          <div className="index-section5__container index-container">
            <img src={indexSection5Image} alt="Person meditating" />
            <h1>Track 3 habits for free, forever.</h1>
            <h2>Your first 3 habits are on us, forever. Join Premium to track unlimited habits.</h2>
            <div className="index-section5__buttons index-buttons">
              <Link to="/signup">
                <button type="button" className="index__ctaButton">
                  Get Started Free
                </button>
              </Link>
            </div>
          </div>
        </div>

        {/* SECTION 6 - ANALYTICS */}
        <div className="index-analytics index-wrapper">
          <div className="index-analytics__container index-container">
            <h1>Analytics for your goals </h1>
            <h2>Visualize the data behind your goals and watch as your “I wish I coulds” become a reality</h2>
            <div className="index-analytics__image">
              <Lottie options={{ loop: true, autoplay: true, animationData: ProgressBar }} />
            </div>
            <div className="index-analytics__buttons index-buttons">
              <a href="https://apps.apple.com/us/app/confetti-habits/id1507853418">
                <img className="index__appStoreButton" src={indexiOSButton} alt="iOS App Store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.confettimobile">
                <img className="index__appStoreButton" src={indexGooglePlayButton} alt="Google Play Store" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Index;
