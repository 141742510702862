import React from 'react';
import arrowIncrease from '../assets/images/arrowIncrease.svg';
import arrowDecrease from '../assets/images/arrowDecrease.svg';

interface OverallProgressBarProps {
  width: number;
  timePeriod: string;
  progressComparison: number;
}

const OverallProgressBar = ({ width, timePeriod, progressComparison }: OverallProgressBarProps) => (
  <div className="overall-progress-bar__container">
    <div className="overall-progress-bar">
      <div className="overall-progress-bar__complete" style={{ width: (width || 0) + '%' }} />
    </div>
    <div className="overall-progress-bar__percentageComparison">
      {timePeriod === 'allTime' ? null : isNaN(progressComparison) ? (
        <h3>
          <img src={arrowIncrease} alt="Arrow pointing up" />
          Up 0% from the {timePeriod} before
        </h3>
      ) : progressComparison >= 0 ? (
        <h3>
          <img src={arrowIncrease} alt="Arrow pointing up" />
          Up {Math.round(progressComparison * 100)}% from the {timePeriod} before
        </h3>
      ) : (
        <h3>
          <img src={arrowDecrease} alt="Arrow pointing down" />
          Down {Math.round(-progressComparison * 100)}% from the {timePeriod} before
        </h3>
      )}
    </div>
    <h3 className="overall-progress-bar__percentageAchieved">{isNaN(width) ? 0 : width}% achieved</h3>
  </div>
);

export default OverallProgressBar;
