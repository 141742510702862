import React, { ChangeEvent, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

interface SettingsNameFormProps {
  user: firebase.User;
  handleSignIn: () => void;
}

const SettingsNameForm = ({ user, handleSignIn }: SettingsNameFormProps) => {
  const [formActive, setFormActive] = useState<boolean>(false);
  const toggleForm = () => setFormActive((prev) => !prev);
  const [name, setName] = useState<string>(user.displayName || '');
  const [error, setError] = useState<boolean>(false);

  const clearFields = () => {
    setName('');
    setError(false);
  };
  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    e.target.value.length >= 1 ? setError(false) : setError(true);
  };
  const handleSubmit = async () => {
    const user = firebase.auth().currentUser;
    await user!.updateProfile({ displayName: name });
    handleSignIn();
    clearFields();
    toggleForm();
  };

  if (formActive) {
    return (
      <div className="settingsProfileName settingsProfile__row">
        <label>Name</label>
        <div className="settingsProfile__form">
          <label>Name</label>
          <input type="text" value={name} onChange={handleChangeName} aria-invalid={error} />
          <div className="settingsProfile__formButtons">
            <button className="whiteButton" onClick={toggleForm}>
              Cancel
            </button>
            {error ? (
              <button className="blueButton blueButton--inactive">Save</button>
            ) : (
              <button className="blueButton" onClick={handleSubmit}>
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="settingsProfile__row">
        <label>Name</label>
        <h3>{user.displayName}</h3>
        <button className="textOnlyButton" onClick={toggleForm}>
          Edit
        </button>
      </div>
    );
  }
};

export default SettingsNameForm;
