import React, { useContext } from 'react';

import { LoggedInContext, UserContext } from '../context';
import useToggle from '../hooks/useToggle';
import CancelSubscriptionModal from '../components/CancelSubscriptionModal';
import DeleteModal from '../components/DeleteModal';
import SettingsProfile from '../components/SettingsProfile';
import SettingsSubscription from '../components/SettingsSubscription';
import SettingsHelp from '../components/SettingsHelp';
import '../styles/Settings.scss';

const Settings = () => {
  const { handleSignIn, handleSignOut } = useContext(UserContext);
  const { user, userSettings } = useContext(LoggedInContext);
  const [cancelSubscriptionModalActive, toggleCancelSubscriptionModal] = useToggle(false);
  const [deleteModalActive, toggleDeleteModal] = useToggle(false);

  return (
    <div className="settings">
      {cancelSubscriptionModalActive && (
        <CancelSubscriptionModal
          toggleCancelSubscriptionModal={toggleCancelSubscriptionModal}
          userSettings={userSettings}
        />
      )}
      {deleteModalActive && (
        <DeleteModal toggleDeleteModal={toggleDeleteModal} user={user} handleSignOut={handleSignOut} />
      )}
      <div className="settings__container">
        <h1>Account Settings</h1>
        <SettingsProfile user={user} userSettings={userSettings} handleSignIn={handleSignIn} />
        <SettingsSubscription toggleCancelSubscriptionModal={toggleCancelSubscriptionModal} />
        <SettingsHelp />
        <div className="settingsDelete" onClick={toggleDeleteModal}>
          <h2>Delete Account</h2>
        </div>
      </div>
    </div>
  );
};

export default Settings;
