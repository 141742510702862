import React, { useState, useEffect, useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { CSSTransition } from 'react-transition-group';
import { setDay, format } from 'date-fns';

import HabitLimits from './HabitLimits';
import AddTaskFormSuccess from './AddTaskFormSuccess';
import { DashboardContext, LoggedInContext, UserContext } from '../context';
import {
  useTaskName,
  useToDoNotToDo,
  useWeeklyFrequency,
  useColor,
  handleAddTask,
  handleEditTask,
} from '../hooks/addTaskHooks';

import '../styles/AddTaskForm.scss';
import exit from '../assets/images/exit.svg';
import helpCircle from '../assets/images/help-circle.svg';

interface AddTaskFormProps {
  formEdit: string | null;
}

const AddTaskForm = ({ formEdit }: AddTaskFormProps) => {
  const { tasks, userSettings, setNotification, setNotificationTask, togglePaymentModal } = useContext(LoggedInContext);
  const { subscriptionState } = useContext(UserContext);
  const { toggleAddTaskForm } = useContext(DashboardContext);
  const [lastAddedTaskSuccess, setLastAddedTaskSuccess] = useState<boolean>(false);
  const [notToDoHover, setNotToDoHover] = useState<boolean>(false);
  const [lastAddedTask, setLastAddedTask] = useState<Task | null>(null);

  const { taskName, setTaskName, taskNameError, setTaskNameError, handleChangeTaskName } = useTaskName(
    formEdit ? tasks[formEdit].name : ''
  );
  const { notToDo, setNotToDo } = useToDoNotToDo(formEdit ? tasks[formEdit].notToDo : false);
  const {
    weeklyFrequency,
    setWeeklyFrequency,
    weeklyFrequencyError,
    setWeeklyFrequencyError,
    handleFrequencyChange,
    handleEverydayFrequency,
    everyDayFrequency,
    weekdayFrequency,
    handleWeekdayFrequency,
  } = useWeeklyFrequency(formEdit ? tasks[formEdit].weeklyFrequency : null);
  const { taskColor, taskColorValue, randomizeColor } = useColor(formEdit ? tasks[formEdit].color : null);

  useEffect(() => {
    if (!formEdit) randomizeColor(tasks);
    // eslint-disable-next-line
  }, []);

  const task: Task = {
    name: taskName,
    notToDo,
    color: taskColor,
    weeklyFrequency,
    history: formEdit ? tasks[formEdit].history : {},
    dateCreated: formEdit ? tasks[formEdit].dateCreated : firebase.firestore.Timestamp.now(),
  };

  const resetState = () => {
    setTaskName('');
    setNotToDo(false);
    setWeeklyFrequency({});
    randomizeColor(tasks);
  };
  const validateForm = () => {
    if (taskName !== '' && Object.values(weeklyFrequency).includes(true)) return true;
    if (taskName === '') setTaskNameError(true);
    if (!Object.values(weeklyFrequency).includes(true)) setWeeklyFrequencyError(true);
    return false;
  };
  const handleAddAnother = () => {
    if (subscriptionState === 'SUBSCRIPTION_INACTIVE' && Object.keys(tasks).length > 2) {
      toggleAddTaskForm();
      togglePaymentModal();
    }
    if (validateForm()) {
      handleAddTask(task, userSettings.user);
      setLastAddedTask(task);
      setLastAddedTaskSuccess(true);
      setTimeout(() => setLastAddedTaskSuccess(false), 5000);
      resetState();
    }
  };
  const handleSubmit = () => {
    if (validateForm()) {
      if (formEdit) {
        handleEditTask(task, formEdit);
        setNotification(`${task.name} has been edited`);
        setNotificationTask(task);
      } else {
        handleAddTask(task, userSettings.user);
        setNotification(`${task.name} has been added`);
        setNotificationTask(task);
      }
      toggleAddTaskForm();
    }
  };

  return (
    <div className="form-modal">
      <div className="form-modal--outsideForm" onClick={toggleAddTaskForm} />
      <div className="task-form">
        <button className="exitButton task-form__exitButton" onClick={() => toggleAddTaskForm()}>
          <img src={exit} alt="exit" />
        </button>
        <CSSTransition
          in={lastAddedTaskSuccess}
          timeout={300}
          classNames="task-form__addSuccess"
          mountOnEnter
          unmountOnExit
        >
          <AddTaskFormSuccess
            lastAddedTask={lastAddedTask}
            toggleLastAddedTask={() => setLastAddedTaskSuccess(false)}
          />
        </CSSTransition>

        <div className="task-form__header">
          <div className="task-form__colorHighlight" style={{ backgroundColor: taskColorValue }} />
          <h2 className="task-form__mainHeading">{formEdit ? 'Edit Habit' : 'Add Habit'}</h2>
          <h3 className="task-form__mainSubheading">Tackle your goals in daily doses</h3>
        </div>
        <hr />

        <HabitLimits dark />
        <div className="task-form__nameSection">
          <div className="task-form__sectionTop">
            <h3 className="task-form__formHeading">1. Name this habit</h3>
            {taskNameError && <h4 className="task-form__errorText">This is a required field.</h4>}
          </div>
          <input
            autoFocus
            className={taskNameError ? 'task-form__nameInput task-form--inputError' : 'task-form__nameInput'}
            type="text"
            id="name"
            value={taskName}
            placeholder={'Habit name'}
            onChange={handleChangeTaskName}
          />
        </div>

        <div className="task-form__toDoNotToDoSection">
          <h3 className="task-form__formHeading">2. Habit Type</h3>
          <div className="task-form__toDoNotToDoSectionBottom">
            <div
              className={notToDo ? 'task-form__radioButton' : 'task-form__radioButton task-form__radioButton--active'}
              onClick={() => setNotToDo(false)}
            />
            <label onClick={() => setNotToDo(false)} className="to-do-label">
              To-Do
            </label>
            <div
              className={notToDo ? 'task-form__radioButton  task-form__radioButton--active' : 'task-form__radioButton'}
              onClick={() => setNotToDo(true)}
            />
            <label onClick={() => setNotToDo(true)} className="to-do-label">
              Not-To-Do
            </label>
            <img
              src={helpCircle}
              onMouseEnter={() => setNotToDoHover(true)}
              onMouseLeave={() => setNotToDoHover(false)}
              alt="Circle with a question mark"
            />
            {!notToDoHover || (
              <div className="task-form__toDoNotToDoHover">
                <h4>Not-To-Do</h4>
                <p>A negative habit that keeps you from achieving your goals</p>
              </div>
            )}
          </div>
        </div>

        <div className="task-form__weeklyFrequencySection">
          <div className="task-form__sectionTop">
            <h3 className="task-form__formHeading">3. Weekly frequency</h3>
            {!weeklyFrequencyError || <h4 className="task-form__errorText">This is a required field.</h4>}
          </div>
          <div className="task-form__weeklyFrequencySectionBottom">
            {[1, 2, 3, 4, 5, 6, 0].map((dayNumber) => (
              <label
                className={
                  weeklyFrequency[dayNumber]
                    ? 'checkbox-label checkbox-label--active'
                    : weeklyFrequencyError
                    ? 'checkbox-label checkbox-label--inactive task-form--inputError'
                    : 'checkbox-label checkbox-label--inactive'
                }
                key={dayNumber}
              >
                {format(setDay(new Date(), dayNumber), 'iii')}
                <input
                  type="checkbox"
                  checked={weeklyFrequency[dayNumber]}
                  onChange={() => handleFrequencyChange(dayNumber)}
                />
              </label>
            ))}
            <div className="task-form__weeklyFrequencySectionButtons">
              <button
                onClick={handleWeekdayFrequency}
                className={
                  weekdayFrequency()
                    ? 'task-form__everydayButton task-form__everydayButton--active'
                    : weeklyFrequencyError
                    ? 'task-form__everydayButton task-form--inputError'
                    : 'task-form__everydayButton'
                }
              >
                Week days
              </button>
              <button
                onClick={handleEverydayFrequency}
                className={
                  everyDayFrequency()
                    ? 'task-form__everydayButton task-form__everydayButton--active'
                    : weeklyFrequencyError
                    ? 'task-form__everydayButton task-form--inputError'
                    : 'task-form__everydayButton'
                }
              >
                Every day
              </button>
            </div>
          </div>
        </div>

        <div className="task-form__buttons">
          <button className="blueButton task-form__submitButton" onClick={handleSubmit}>
            {formEdit ? 'Save Changes' : 'Add Habit'}
          </button>
          {formEdit ? (
            <button className="whiteButton task-form__addAnotherButton" onClick={toggleAddTaskForm}>
              Cancel
            </button>
          ) : (
            <button className="whiteButton task-form__addAnotherButton" onClick={handleAddAnother}>
              + Add Another
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddTaskForm;
