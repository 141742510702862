import React, { useState } from 'react';
import axios from 'axios';
import HeaderBlue from '../components/HeaderBlue';
import Footer from '../components/Footer';

const ForgotPassword = () => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [completeError, setCompleteError] = useState<boolean>(false);

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmail(e.target.value);
    if (e.target.value.match(reg)) setEmailError(false);
    else setEmailError(true);
  };

  const handleSubmit = async () => {
    if (!emailError) {
      const response = await axios.post(`/password/send_reset`, { email });
      if (response.data.success) setIsComplete(true);
      else setCompleteError(true);
    }
  };

  return (
    <>
      <HeaderBlue />
      <div className="login__wrapper">
        {isComplete ? (
          <div className="login__container">
            <h1>Forgot Password?</h1>
            <h3 className="login__resetSuccess">
              Please check your email! You should get a link where you can reset your password.
            </h3>
            <button className="login__submitButton" aria-disabled={true}>
              Password reset sent!
            </button>
          </div>
        ) : (
          <div className="login__container">
            <h1>Forgot Password?</h1>
            {completeError && <h3 className="login__error">We didn't find an account for that email address.</h3>}
            <input
              type="email"
              value={email}
              aria-invalid={emailError}
              onChange={(e) => handleChangeEmail(e)}
              placeholder="Enter your email"
            />
            <button className="login__submitButton" onClick={handleSubmit}>
              Send me a password reset, please!
            </button>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
