import React, { useContext } from 'react';
import { useMonthNavigation, useRefresher } from '../hooks';
import { format, isSameMonth } from 'date-fns';

import { DashboardContext, LoggedInContext } from '../context';
import TaskHelper from '../services/TaskHelper';
import OverallProgressBar from './OverallProgressBar';
import MonthSummary from './MonthSummary';
import MonthCalendar from './MonthCalendar';
import DateSelectors from './DateSelectors';
import ViewSelector from './ViewSelector';
import '../styles/Month.scss';

const Month = () => {
  const { tasks, userSettings } = useContext(LoggedInContext);
  const { summaryView } = useContext(DashboardContext);
  const {
    monthStart,
    lastMonth,
    handleLastMonth,
    currentMonth,
    handleCurrentMonth,
    handleNextMonth,
  } = useMonthNavigation();
  const { mounted, refreshState } = useRefresher();
  const isFirstMonth = isSameMonth(monthStart, userSettings.createdAt.toDate());

  const monthlyGoal = TaskHelper.monthlyGoal(tasks, monthStart);
  const progressComparison = monthlyGoal - TaskHelper.monthlyGoal(tasks, lastMonth);
  const monthlyGoalPercentage = Math.round(monthlyGoal * 100);

  return (
    <div className="month">
      <div className="main-top">
        <DateSelectors
          leftArrowAction={handleLastMonth}
          rightArrowAction={handleNextMonth}
          backInactive={isFirstMonth}
          forwardInactive={isSameMonth(currentMonth, monthStart)}
        />
        <h1 className="main-top-header">{format(monthStart, 'MMMM yyyy')}</h1>
        <div className="main-top__left">
          {!isSameMonth(currentMonth, monthStart) && (
            <button type="button" className="textOnlyButton" onClick={handleCurrentMonth}>
              Current
            </button>
          )}
          <ViewSelector refreshState={refreshState} />
        </div>
      </div>
      <OverallProgressBar width={monthlyGoalPercentage} progressComparison={progressComparison} timePeriod="month" />
      {summaryView ? (
        <MonthSummary monthStart={monthStart} mounted={mounted} />
      ) : (
        <MonthCalendar monthStart={monthStart} />
      )}
    </div>
  );
};

export default Month;
