import { Dispatch, useState } from 'react';

interface useDeleteTaskModal {
  deleteTaskTarget: string | null;
  setDeleteTaskTarget: Dispatch<string | null>;
  deleteTaskModalActive: boolean;
  setDeleteTaskModalActive: Dispatch<boolean>;
  toggleDeleteTaskModal: (taskId: string | null) => void;
}

const useDeleteTaskModal = (): useDeleteTaskModal => {
  const [deleteTaskTarget, setDeleteTaskTarget] = useState<string | null>(null);
  const [deleteTaskModalActive, setDeleteTaskModalActive] = useState(false);
  const toggleDeleteTaskModal = (taskId: string | null) => {
    setDeleteTaskTarget(taskId);
    setDeleteTaskModalActive((prevProps) => !prevProps);
  };
  return {
    deleteTaskTarget,
    setDeleteTaskTarget,
    deleteTaskModalActive,
    setDeleteTaskModalActive,
    toggleDeleteTaskModal,
  };
};

export default useDeleteTaskModal;
