import React, { useContext } from 'react';
import { add, format, isSameDay, isSameISOWeek } from 'date-fns';

import { DashboardContext, LoggedInContext } from '../context';
import { useRefresher, useWeekNavigation } from '../hooks';
import TaskHelper from '../services/TaskHelper';
import DateSelectors from './DateSelectors';
import ViewSelector from './ViewSelector';
import OverallProgressBar from './OverallProgressBar';
import WeekSummary from './WeekSummary';
import WeekGrid from './WeekGrid';
import '../styles/Week.scss';

const Week = () => {
  const { tasks, userSettings } = useContext(LoggedInContext);
  const { summaryView } = useContext(DashboardContext);
  const { weekStart, lastWeek, handleLastWeek, currentWeek, handleCurrentWeek, handleNextWeek } = useWeekNavigation();
  const { mounted, refreshState } = useRefresher();
  const isFirstWeek = isSameISOWeek(weekStart, userSettings.createdAt.toDate());

  const filteredTasks = TaskHelper.filter(tasks, weekStart, add(weekStart, { weeks: 1 }));
  const weeklyGoal = TaskHelper.weeklyGoal(filteredTasks, weekStart);
  const progressComparison = weeklyGoal - TaskHelper.weeklyGoal(filteredTasks, lastWeek);
  const weeklyGoalPercentage = Math.round(weeklyGoal * 100);

  return (
    <div className="week">
      <div className="main-top">
        <DateSelectors
          leftArrowAction={handleLastWeek}
          rightArrowAction={handleNextWeek}
          backInactive={isFirstWeek}
          forwardInactive={isSameDay(currentWeek, weekStart)}
        />
        <h1 className="main-top-header">
          {format(weekStart, 'E, MMM dd')} - {format(add(weekStart, { days: 6 }), 'E, MMM dd')}
        </h1>
        <div className="main-top__left">
          {!isSameDay(weekStart, currentWeek) && (
            <button type="button" className="textOnlyButton" onClick={handleCurrentWeek}>
              Current
            </button>
          )}
          <ViewSelector refreshState={refreshState} />
        </div>
      </div>
      <OverallProgressBar width={weeklyGoalPercentage} progressComparison={progressComparison} timePeriod="week" />
      {summaryView ? (
        <WeekSummary tasks={filteredTasks} weekStart={weekStart} mounted={mounted} />
      ) : (
        <WeekGrid tasks={filteredTasks} weekStart={weekStart} />
      )}
    </div>
  );
};

export default Week;
