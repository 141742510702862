import { useEffect, useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../context';

const useConfirmUpdateEmail = (setNotification: (notification: string) => void) => {
  const { handleSignIn } = useContext(UserContext);
  const location = useLocation();
  useEffect(() => {
    const locationObj = qs.parse(location.search);
    const confirmEmailChange = async () => {
      const firebaseToken = await firebase.auth().currentUser!.getIdToken();
      const res = await axios.post('/user/confirm_email_change', { firebaseToken, token: locationObj.updateEmail });
      if (res.data.success) {
        setNotification('Your email was successfully updated.');
        handleSignIn();
      } else {
        setNotification('There was a problem with updating your email.');
      }
    };
    if (locationObj.updateEmail) confirmEmailChange();
  }, [handleSignIn, location, setNotification]);
};

export default useConfirmUpdateEmail;
