import firebase from 'firebase/app';
import 'firebase/auth';

export const reauthenticate = async (firebaseUser: firebase.User) => {
  const googleProvider = new firebase.auth.GoogleAuthProvider();
  const facebookProvider = new firebase.auth.FacebookAuthProvider();
  const provider = firebaseUser.providerData[0]?.providerId;
  if (provider === 'google.com') {
    return firebase.auth().signInWithPopup(googleProvider);
  }
  if (provider === 'facebook.com') {
    return firebase.auth().signInWithPopup(facebookProvider);
  }
};
