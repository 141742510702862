import { useState, Dispatch } from 'react';
import { startOfMonth, sub, add } from 'date-fns';

export interface MonthNavigation {
  monthStart: Date;
  setMonthStart: Dispatch<Date>;
  lastMonth: Date;
  handleLastMonth: () => void;
  currentMonth: Date;
  handleCurrentMonth: () => void;
  nextMonth: Date;
  handleNextMonth: () => void;
}

const useMonthNavigation = (): MonthNavigation => {
  const currentMonth = startOfMonth(new Date());
  const [monthStart, setMonthStart] = useState(currentMonth);
  const lastMonth = sub(new Date(monthStart), { months: 1 });
  const handleLastMonth = () => setMonthStart(lastMonth);
  const handleCurrentMonth = () => setMonthStart(currentMonth);
  const nextMonth = add(new Date(monthStart), { months: 1 });
  const handleNextMonth = () => setMonthStart(nextMonth);

  return {
    monthStart,
    setMonthStart,
    lastMonth,
    handleLastMonth,
    currentMonth,
    handleCurrentMonth,
    nextMonth,
    handleNextMonth,
  };
};

export default useMonthNavigation;
