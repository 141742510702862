import { useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import confetti from 'canvas-confetti';
import { format } from 'date-fns';

import colors from '../config/colors';
import TaskHelper from '../services/TaskHelper';
import apiClient from '../services/apiClient';
import { LoggedInContext } from '../context';

interface useTasks {
  toggleTask: (task: Task, taskId: string, taskDate: Date, event: React.MouseEvent) => void;
  deleteTask: (taskId: string) => void;
  archiveTask: (taskId: string) => void;
  unarchiveTask: (taskId: string) => void;
}

const useTasks = (): useTasks => {
  const { tasks } = useContext(LoggedInContext);

  const toggleTask = (taskId: string, date: Date) => {
    const dateString = format(date, 'yyyy-MM-dd');
    const currentHistory = tasks[taskId].history;
    const currentRecord = tasks[taskId].record;
    const currentState = tasks[taskId].history[dateString];
    if (!currentState)
      firebase.analytics().logEvent('habit_completed', {
        uid: tasks[taskId].user,
        name: tasks[taskId].name,
        taskId,
        habitDate: dateString,
      });
    firebase
      .firestore()
      .collection('Tasks')
      .doc(taskId)
      .update({
        history: { ...currentHistory, [dateString]: !currentState },
        record: { ...currentRecord, [dateString]: currentState ? 'incomplete' : 'completed' },
      });
  };
  const deleteTask = (taskId: string) => {
    firebase.firestore().collection('Tasks').doc(taskId).delete();
    firebase.analytics().logEvent('habit_deleted', {
      uid: tasks[taskId].user,
      name: tasks[taskId].name,
      taskId,
    });
  };
  const archiveTask = (taskId: string) => {
    apiClient.archiveTask(taskId);
    firebase.analytics().logEvent('habit_archived', {
      uid: tasks[taskId].user,
      name: tasks[taskId].name,
      taskId,
    });
  };
  const unarchiveTask = (taskId: string) => {
    apiClient.unarchiveTask(taskId);
    firebase.analytics().logEvent('habit_unarchived', {
      uid: tasks[taskId].user,
      name: tasks[taskId].name,
      taskId,
    });
  };

  const toggleTaskEvent = (task: Task, taskId: string, taskDate: Date, event: React.MouseEvent) => {
    const numberIncomplete = Object.values(tasks).reduce(
      (acc, task) => (TaskHelper.dayStatus(task, taskDate) === 'incomplete' ? acc + 1 : acc),
      0
    );
    if (TaskHelper.dayStatus(task, taskDate) === 'incomplete' && numberIncomplete === 1) {
      confetti({
        particleCount: 40,
        startVelocity: 40,
        colors: colors.map((color) => color.value),
        spread: 180,
        origin: {
          x: event.clientX / window.innerWidth,
          y: event.clientY / window.innerHeight,
        },
      });
    } else if (TaskHelper.dayStatus(task, taskDate) === 'incomplete') {
      confetti({
        particleCount: 25,
        startVelocity: 25,
        colors: [task.color.value],
        spread: 180,
        origin: {
          x: event.clientX / window.innerWidth,
          y: event.clientY / window.innerHeight,
        },
      });
    }
    toggleTask(taskId, taskDate);
  };

  return {
    toggleTask: toggleTaskEvent,
    deleteTask,
    archiveTask,
    unarchiveTask,
  };
};

export default useTasks;
