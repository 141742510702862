import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';

import { LoggedInContext, UserContext } from '../context';
import UserAvatar from './UserAvatar';
import HabitLimits from './HabitLimits';
import logoWhite from '../assets/images/logo-white.svg';
import logoBlue from '../assets/images/logo.svg';
import down from '../assets/images/down.svg';
import downDark from '../assets/images/down-dark.svg';
import starIcon from '../assets/images/starIcon.svg';
import userIcon from '../assets/images/userIcon.svg';
import signOutIcon from '../assets/images/signOutIcon.svg';
import chromeIcon from '../assets/images/chromeIcon.svg';
import '../styles/Header.scss';

const LoggedInHeader = () => {
  const { handleSignOut } = useContext(UserContext);
  const { user, userSettings, subscriptionState } = useContext(LoggedInContext);
  const { togglePaymentModal } = useContext(LoggedInContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const toggleIsExpanded = () => setIsExpanded((prev) => !prev);
  const isMobile = document.body.clientWidth < 480;
  const { displayName, email, photoURL } = user;
  const { selectedAvatar } = userSettings;

  return (
    <header className={isMobile ? 'header --white' : 'header'} onMouseLeave={() => setIsExpanded(false)}>
      <div className="header__container">
        <Link to="/">
          <img src={isMobile ? logoBlue : logoWhite} alt="Confetti's logo" />
        </Link>
        {!isMobile && <HabitLimits hasPopup />}
        <div className="header__menu" onClick={toggleIsExpanded}>
          <UserAvatar selectedAvatar={selectedAvatar} photoURL={photoURL} />
          <img className="header__menuIcon" src={isMobile ? downDark : down} alt="A triangle pointing down" />
        </div>

        {isExpanded && (
          <div className="headerDropdown">
            <div className="headerDropdown__top">
              <UserAvatar selectedAvatar={selectedAvatar} photoURL={photoURL} />
              <div className="headerDropdown__topRight">
                <h2>{displayName}</h2>
                <h3>{email}</h3>
              </div>
            </div>
            <div className="headerDropdown__bottom">
              {subscriptionState !== 'SUBSCRIPTION_ACTIVE_RENEW' && (
                <button type="button" data-type="upgrade" onClick={togglePaymentModal}>
                  <img src={starIcon} alt="A star" />
                  Upgrade to Premium
                </button>
              )}
              <a
                href="https://chrome.google.com/webstore/detail/hicbenbldlkghoodigniooplopgjjpnh/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button type="button">
                  <img src={chromeIcon} alt="Google Chrome Icon" />
                  Get Chrome Extension
                </button>
              </a>
              <Link to="/settings">
                <button type="button">
                  <img src={userIcon} alt="A silhouette of a person" />
                  Account Settings
                </button>
              </Link>
              <button type="button" onClick={handleSignOut}>
                <img src={signOutIcon} alt="Sign out icon" />
                Sign Out
              </button>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default LoggedInHeader;
