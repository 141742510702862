import React, { useState, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';

import { LoggedInContext, DashboardContext } from '../context';
import { useTasks, useToggle, useDeleteTaskModal, useArchiveTaskModal, useTaskForm } from '../hooks';
import Day from '../sections/Day';
import DashboardGreeting from '../components/DashboardGreeting';
import DateRangeSelector from '../components/DateRangeSelector';
import DashboardMobileTabBar from '../components/DashboardMobileTabBar';
import AllTime from '../components/AllTime';
import Week from '../components/Week';
import Month from '../components/Month';
import Year from '../components/Year';
import BedtimeForm from '../components/BedtimeForm';
import DeleteTaskModal from '../components/DeleteTaskModal';
import ArchiveTaskModal from '../components/ArchiveTaskModal';
import AddTaskForm from '../components/AddTaskForm';
import '../styles/Dashboard.scss';
import '../styles/Main.scss';

const Dashboard = () => {
  const { tasks, subscriptionState, togglePaymentModal } = useContext(LoggedInContext);
  const { deleteTask, archiveTask, toggleTask: toggleTaskOriginal } = useTasks();
  const [bedtimeModalActive, toggleBedtimeModal] = useToggle(false);
  const { deleteTaskModalActive, deleteTaskTarget, toggleDeleteTaskModal } = useDeleteTaskModal();
  const { archiveTaskModalActive, archiveTaskTarget, toggleArchiveTaskModal } = useArchiveTaskModal();
  const {
    formEdit,
    addTaskFormActive,
    toggleAddTaskForm: toggleAddTaskFormOriginal,
    toggleEditTaskForm,
  } = useTaskForm();
  const [dateRange, setDateRange] = useState<DashboardRange>('week');
  const [summaryView, setSummaryView] = useState<boolean>(false);

  const filteredTasks = Object.values(tasks).filter((task) => !task.dateInactive);
  const toggleTask =
    subscriptionState === 'SUBSCRIPTION_INACTIVE' && Object.values(filteredTasks).length > 3
      ? togglePaymentModal
      : toggleTaskOriginal;
  const toggleAddTaskForm =
    subscriptionState === 'SUBSCRIPTION_INACTIVE' && Object.values(filteredTasks).length > 2 && !formEdit
      ? togglePaymentModal
      : toggleAddTaskFormOriginal;

  return (
    <DashboardContext.Provider
      value={{
        dateRange,
        setDateRange,
        summaryView,
        setSummaryView,
        toggleTask,
        toggleAddTaskForm,
        toggleEditTaskForm,
        toggleBedtimeModal,
        toggleDeleteTaskModal,
        toggleArchiveTaskModal,
      }}
    >
      <div className="dashboard">
        {bedtimeModalActive && <BedtimeForm toggleBedtimeModal={toggleBedtimeModal} />}
        {deleteTaskModalActive && deleteTaskTarget && (
          <DeleteTaskModal
            taskId={deleteTaskTarget}
            task={tasks![deleteTaskTarget]}
            toggleDeleteTaskModal={toggleDeleteTaskModal}
            deleteTask={deleteTask}
          />
        )}
        {archiveTaskModalActive && archiveTaskTarget && (
          <ArchiveTaskModal taskId={archiveTaskTarget} task={tasks![archiveTaskTarget]} archiveTask={archiveTask} />
        )}
        <CSSTransition in={addTaskFormActive} timeout={300} classNames="form-modal" mountOnEnter unmountOnExit>
          <AddTaskForm key="form" formEdit={formEdit} />
        </CSSTransition>

        <div className="dashboard__main">
          <div className="dashboard__left">
            <DashboardGreeting />
            <DateRangeSelector />
            {dateRange === 'week' && <Week />}
            {dateRange === 'month' && <Month />}
            {dateRange === 'year' && <Year />}
            {dateRange === 'alltime' && <AllTime />}
          </div>
          <div className="dashboard__right">
            <Day />
          </div>
        </div>
        <DashboardMobileTabBar />
      </div>
    </DashboardContext.Provider>
  );
};

export default Dashboard;
