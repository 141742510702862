import React, { ChangeEvent, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

interface SettingsEmailFormProps {
  user: firebase.User;
}

const SettingsEmailForm = ({ user }: SettingsEmailFormProps) => {
  const [formActive, setFormActive] = useState(false);
  const toggleForm = () => setFormActive((prev) => !prev);

  const [email, setEmail] = useState<string>(user.email!);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');

  const signInProvider = firebase.auth().currentUser?.providerData[0]?.providerId;
  const isOAuth = signInProvider && ['facebook.com', 'google.com'].includes(signInProvider);

  const clearFields = () => {
    setEmail(user.email!);
    setEmailError(false);
    setPassword('');
    setPasswordError(false);
  };
  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmail(e.target.value);
    if (e.target.value.match(reg)) setEmailError(false);
  };
  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value.length >= 6) setPasswordError(false);
  };
  const handleSubmit = async () => {
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.match(reg)) return setEmailError(true);
    if (password.length < 6) return setPasswordError(true);
    try {
      const currentUser = firebase.auth().currentUser;
      await firebase.auth().signInWithEmailAndPassword(currentUser!.email!, password);
      const firebaseToken = await currentUser!.getIdToken();
      const res = await axios.post('/user/request_email_change', { firebaseToken, email });
      if (res.data.success) {
        clearFields();
        setSuccess('Please check your email to confirm.');
        setTimeout(() => {
          setSuccess('');
          toggleForm();
        }, 5000);
      }
    } catch (e) {
      setPasswordError(true);
    }
  };

  if (formActive) {
    return (
      <div className="settingsProfile__row">
        <label>Email</label>
        <div className="settingsProfile__form">
          {success && <div className="settingsProfile__formSuccess">{success}</div>}
          <label>New Email</label>
          <input type="text" value={email} onChange={handleChangeEmail} aria-invalid={emailError} />
          <label>Confirm your Password</label>
          <input type="password" value={password} onChange={handleChangePassword} aria-invalid={passwordError} />
          <div className="settingsProfile__formButtons">
            <button className="whiteButton" onClick={toggleForm}>
              Cancel
            </button>
            {passwordError || emailError ? (
              <button className="blueButton blueButton--inactive">Save</button>
            ) : (
              <button className="blueButton" onClick={handleSubmit}>
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="settingsProfile__row">
        <label>Email</label>
        <h3>{user.email}</h3>
        {!isOAuth && (
          <button className="textOnlyButton" onClick={toggleForm}>
            Edit
          </button>
        )}
      </div>
    );
  }
};

export default SettingsEmailForm;
