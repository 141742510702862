import { ChangeEvent, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

export const useBedtimeHour = (initialValue?: number) => {
  const [bedtimeHour, setBedtimeHour] = useState<number | undefined>(initialValue);
  const [bedtimeHourError, setBedtimeHourError] = useState<boolean>(false);
  const handleSetBedtimeHour = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.valueAsNumber >= 0 && e.target.valueAsNumber <= 12) {
      setBedtimeHour(e.target.valueAsNumber);
      setBedtimeHourError(false);
    } else {
      setBedtimeHourError(true);
    }
  };
  return {
    bedtimeHour,
    setBedtimeHour,
    handleSetBedtimeHour,
    bedtimeHourError,
  };
};

export const useBedtimeMinutes = (initialValue?: number) => {
  const [bedtimeMinutes, setBedtimeMinutes] = useState<number | undefined>(initialValue);
  const [bedtimeMinutesError, setBedtimeMinutesError] = useState<boolean>(false);
  const handleSetBedtimeMinutes = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.valueAsNumber >= 0 && e.target.valueAsNumber < 60) {
      setBedtimeMinutes(e.target.valueAsNumber);
      setBedtimeMinutesError(false);
    } else {
      setBedtimeMinutesError(true);
    }
  };
  return {
    bedtimeMinutes,
    setBedtimeMinutes,
    bedtimeMinutesError,
    setBedtimeMinutesError,
    handleSetBedtimeMinutes,
  };
};

export const useBedtimeAMPM = (initialValue: string | undefined = 'am') => {
  const [ampm, setAMPM] = useState<string>(initialValue);
  return {
    ampm,
    setAMPM,
  };
};

export const handleBedtimeSubmit = ({
  bedtimeHour,
  bedtimeMinutes,
  ampm,
  uuid,
}: {
  bedtimeHour: number | undefined;
  bedtimeMinutes: number | undefined;
  ampm: string;
  uuid: string;
}) => {
  firebase.firestore().collection('userSettings').doc(uuid).update({
    bedtimeHour,
    bedtimeMinutes,
    ampm: ampm,
  });
};
