import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import { Link } from 'react-router-dom';
import { CardElement, injectStripe, ReactStripeElements } from 'react-stripe-elements';
import Lottie from 'react-lottie';

import ConfettiHelper from '../services/ConfettiHelper';
import { LoggedInContext } from '../context';
import '../styles/PaymentModal.scss';
import loading from '../assets/lottie/loading.json';
import exit from '../assets/images/exit.svg';
import creditCardIcons from '../assets/images/creditCards.svg';
import apiClient from '../services/apiClient';

const PaymentModal = ({ stripe }: { stripe?: ReactStripeElements.StripeProps }) => {
  const { togglePaymentModal } = useContext(LoggedInContext);
  const [paymentFrequency, setPaymentFrequency] = useState('year');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [hasError, setHasError] = useState('');
  const [name, setName] = useState('');
  const [prices, setPrices] = useState({ month: 0, year: 0 });

  useEffect(() => {
    const fetchPrices = async () => {
      const { data } = await apiClient.getPrices();
      setPrices(data);
    };
    fetchPrices();
  }, []);

  const handleSubmitPayment = async () => {
    setIsLoading(true);
    if (!name) {
      setHasError('Please input the name on the card.');
      return setIsLoading(false);
    }
    setHasError('');
    const { token } = await stripe!.createToken({ name });
    if (!token) return setIsLoading(false);
    const firebaseToken = await firebase.auth().currentUser!.getIdToken();
    const response = await axios.post('/subscription/update', {
      stripeToken: token.id,
      firebaseToken,
      paymentFrequency,
      isNewSubscription: true,
    });
    if (response.data.success) {
      setIsLoading(false);
      setIsSuccess(true);
      setTimeout(() => togglePaymentModal(), 3000);
    } else {
      setIsLoading(false);
      setIsSuccess(false);
      setHasError(response.data.message);
    }
  };

  const renderSuccess = () => {
    ConfettiHelper.celebrate();
    return (
      <div className="payment-container payment-container--success">
        <button type="button" onClick={togglePaymentModal} className="exitButton payment__exitButton">
          <img src={exit} alt="Exit icon" />
        </button>
        <h1>Congrats! You&apos;re subscribed!</h1>
        <h2>Keep working towards your goals!</h2>
        <Link to="/">
          <button type="button" onClick={togglePaymentModal} className="blueButton">
            Return to Dashboard
          </button>
        </Link>
      </div>
    );
  };
  const renderLoading = () => {
    return (
      <div className="payment-container payment-container--loading">
        <Lottie options={{ loop: true, autoplay: true, animationData: loading }} width={250} height={200} />
      </div>
    );
  };

  return (
    <div
      className="form-modal"
      onClick={(e) => {
        if ((e.target as Element).className === 'payment-wrapper') togglePaymentModal();
      }}
    >
      <div className="payment-wrapper">
        <div className="payment-container">
          {isSuccess && renderSuccess()}
          {isLoading && renderLoading()}
          <button type="button" onClick={togglePaymentModal} className="exitButton payment__exitButton">
            <img src={exit} alt="Exit icon" />
          </button>
          <h1>Upgrade for unlimited habits</h1>
          <h2>Get unlimited habits, support a startup, and achieve your goals</h2>
          <div className="payment-container__options">
            <h2>1. Choose a plan</h2>
            <button
              type="button"
              className="payment-container__option"
              data-active={paymentFrequency === 'month'}
              onClick={() => setPaymentFrequency('month')}
            >
              <h3>
                Monthly{' '}
                <span role="img" aria-label="plant emoji">
                  🌱
                </span>
              </h3>
              <h4>Unlimited Habits</h4>
              <h1>${(prices.month / 100).toFixed(2)}</h1>
            </button>
            <button
              type="button"
              className="payment-container__option"
              data-active={paymentFrequency === 'year'}
              onClick={() => setPaymentFrequency('year')}
            >
              <h3>
                Yearly{' '}
                <span role="img" aria-label="rocket emoji">
                  🚀
                </span>{' '}
              </h3>
              <h4>Unlimited Habits</h4>
              <h1>${(prices.year / 100).toFixed(2)}</h1>
            </button>
          </div>
          <div className="payment-container__mid">
            {paymentFrequency === 'month' ? (
              <h3>
                <span>${(prices.month / 100).toFixed(2)}</span> per month
              </h3>
            ) : (
              <h3>
                <span>${(prices.year / 100).toFixed(2)}</span> per year
              </h3>
            )}
            <img src={creditCardIcons} alt="Credit card logos" />
          </div>
          <div className="payment-container__form">
            {hasError && <p className="error">{hasError}</p>}
            <input type="text" placeholder="Name on Card" value={name} onChange={(e) => setName(e.target.value)} />
            <CardElement style={{ base: { fontSize: '18px', fontWeight: 300 } }} />
            <button type="button" onClick={handleSubmitPayment}>
              Pay{' '}
              {paymentFrequency === 'month'
                ? `$${(prices.month / 100).toFixed(2)}`
                : `$${(prices.year / 100).toFixed(2)}`}
            </button>
            <small>Your plan will automatically renew, cancel anytime.</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectStripe(PaymentModal);
