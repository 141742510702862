import React, { ChangeEvent, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import avatarGreen from '../assets/images/avatarGreen.svg';
import avatarBlue from '../assets/images/avatarBlue.svg';
import avatarPink from '../assets/images/avatarPink.svg';
import avatarPurple from '../assets/images/avatarPurple.svg';
import avatarAqua from '../assets/images/avatarAqua.svg';
import avatarRed from '../assets/images/avatarRed.svg';

interface SettingsAvatarFormProps {
  uid: string;
  selectedAvatar: AvatarType;
  photoURL: string;
  handleSignIn: () => void;
}

const SettingsAvatarForm = ({ uid, selectedAvatar, photoURL, handleSignIn }: SettingsAvatarFormProps) => {
  const avatarUpload = useRef<HTMLInputElement>(null);
  const avatarMap: { [color in AvatarType]: any } = {
    own: avatarBlue,
    green: avatarGreen,
    blue: avatarBlue,
    pink: avatarPink,
    purple: avatarPurple,
    aqua: avatarAqua,
    red: avatarRed,
  };
  const handleSelectAvatar = (option: AvatarType) =>
    firebase.firestore().collection('userSettings').doc(uid).update({ selectedAvatar: option });
  const handleSubmitAvatar = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0];
    if (file) {
      const snapshot = await firebase.storage().ref().child(uid).put(file);
      const downloadURL = await snapshot.ref.getDownloadURL();
      const user = firebase.auth().currentUser;
      await user!.updateProfile({ photoURL: downloadURL });
      handleSignIn();
    }
  };

  const renderAvatarSelector = (color: AvatarType) => {
    if (color === 'own') return null;
    return (
      <div
        className="settingsProfileAvatars__avatar"
        aria-selected={selectedAvatar === color}
        onClick={() => handleSelectAvatar(color)}
        key={color}
      >
        <img src={avatarMap[color]} alt={`${color}-colored default avatar`} />
      </div>
    );
  };

  return (
    <div className="settingsProfile__row">
      <label>Photo</label>
      <div className="settingsProfileAvatars">
        <div
          className="settingsProfileAvatars__avatar"
          aria-selected={selectedAvatar === 'own'}
          onClick={() => handleSelectAvatar('own')}
        >
          <img src={photoURL ? photoURL : avatarGreen} alt="Your selected avatar" />
        </div>
        {Object.keys(avatarMap).map((color) => renderAvatarSelector(color as AvatarType))}
      </div>
      <input type="file" className="settingsProfileAvatars__upload" ref={avatarUpload} onChange={handleSubmitAvatar} />
      <button className="textOnlyButton" onClick={() => avatarUpload.current?.click()}>
        Upload
      </button>
    </div>
  );
};

export default SettingsAvatarForm;
