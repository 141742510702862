import { useState, Dispatch } from 'react';

interface useArchiveTaskModal {
  archiveTaskTarget: string | null;
  setArchiveTaskTarget: Dispatch<string>;
  archiveTaskModalActive: boolean;
  setArchiveTaskModalActive: Dispatch<boolean>;
  toggleArchiveTaskModal: (taskId: string | null) => void;
}

const useArchiveTaskModal = (): useArchiveTaskModal => {
  const [archiveTaskTarget, setArchiveTaskTarget] = useState<string | null>(null);
  const [archiveTaskModalActive, setArchiveTaskModalActive] = useState<boolean>(false);
  const toggleArchiveTaskModal = (taskId: string | null) => {
    setArchiveTaskTarget(taskId);
    setArchiveTaskModalActive((prevProps) => !prevProps);
  };
  return {
    archiveTaskTarget,
    setArchiveTaskTarget,
    archiveTaskModalActive,
    setArchiveTaskModalActive,
    toggleArchiveTaskModal,
  };
};

export default useArchiveTaskModal;
