import { useState, useEffect } from 'react';

interface useSubscriptionState {
  subscriptionState: SubscriptionState;
}

const useSubscriptionState = (userSettings: UserSettings | null): useSubscriptionState => {
  const getSubscriptionState = (settings?: UserSettings | null) => {
    if (!settings || !settings.subscriptionId) return 'SUBSCRIPTION_INACTIVE' as SubscriptionState;
    const { subscriptionStatus, trialEnd, subscriptionAutorenew, subscriptionPaymentMethod } = settings;
    if (subscriptionStatus === 'active' && subscriptionAutorenew)
      return 'SUBSCRIPTION_ACTIVE_RENEW' as SubscriptionState;
    if (subscriptionStatus === 'active' && !subscriptionAutorenew)
      return 'SUBSCRIPTION_ACTIVE_ENDING' as SubscriptionState;
    if (subscriptionStatus === 'trialing' && trialEnd && trialEnd.toDate() < new Date() && subscriptionAutorenew)
      return 'SUBSCRIPTION_ACTIVE_RENEW' as SubscriptionState;
    if (subscriptionStatus === 'trialing' && trialEnd && trialEnd.toDate() < new Date() && !subscriptionAutorenew)
      return 'SUBSCRIPTION_ACTIVE_ENDING' as SubscriptionState;
    if (subscriptionStatus === 'trialing' && !!subscriptionPaymentMethod && !!subscriptionAutorenew)
      return 'SUBSCRIPTION_TRIAL_RENEW' as SubscriptionState;
    if (subscriptionStatus === 'trialing') return 'SUBSCRIPTION_TRIAL_ENDING';
    return 'SUBSCRIPTION_INACTIVE' as SubscriptionState;
  };

  const [subscriptionState, setSubscriptionState] = useState<SubscriptionState>(getSubscriptionState(userSettings));
  useEffect(() => {
    setSubscriptionState(getSubscriptionState(userSettings));
  }, [userSettings]);

  return { subscriptionState };
};

export default useSubscriptionState;
