import React, { useContext } from 'react';
import moment from 'moment';

import { DashboardContext, LoggedInContext } from '../context';

const DashboardGreeting = () => {
  const { user, userSettings } = useContext(LoggedInContext);
  const { toggleBedtimeModal } = useContext(DashboardContext);
  const { displayName } = user!;
  const { ampm, bedtimeHour, bedtimeMinutes } = userSettings!;

  const calculateTimeToBedtime = () => {
    const bedtime = moment()
      .startOf('day')
      .add(ampm === 'am' ? (bedtimeHour === 12 ? 24 : bedtimeHour! + 24) : bedtimeHour! + 12, 'hours')
      .add(bedtimeMinutes, 'minutes');
    const hours = bedtime.diff(moment(), 'hours');
    const minutes = bedtime.diff(moment(), 'minutes') - 60 * hours;
    return { hours, minutes };
  };
  const renderGreetingMessage = () => {
    const currentTime = moment();
    if (currentTime.hour() > 5 && currentTime.hour() < 12) return 'Good morning';
    if (currentTime.hour() >= 12 && currentTime.hour() < 17) return 'Good afternoon';
    return 'Good evening';
  };
  const renderBedtime = () => {
    if (!bedtimeHour) return `Add Your Bedtime`;
    const timeToBedtime = calculateTimeToBedtime();
    if (timeToBedtime.minutes >= 0 && timeToBedtime.hours > 0)
      return `${timeToBedtime.hours} hrs ${timeToBedtime.minutes} mins till bedtime`;
    if (timeToBedtime.minutes >= 0) return `${timeToBedtime.minutes} mins till bedtime`;
    return `${-timeToBedtime.hours} hrs ${-timeToBedtime.minutes} mins past your bedtime`;
  };
  return (
    <div className="dashboard__top">
      <h1>
        {renderGreetingMessage()}
        {displayName && `, ${displayName.split(' ')[0]}`}
      </h1>
      <h2 role="button" onClick={toggleBedtimeModal}>
        {renderBedtime()}
      </h2>
    </div>
  );
};

export default DashboardGreeting;
