import React, { useContext } from 'react';

import { DashboardContext, LoggedInContext } from '../context';
import TaskHelper from '../services/TaskHelper';
import DayItem from './DayItem';
import DayExpired from './DayExpired';
import relaxImg from '../assets/images/relax.png';
import firstHabitIcon from '../assets/images/firstHabitIcon.svg';

interface DayMainProps {
  displayDate: Date;
}

const DayMain = ({ displayDate }: DayMainProps) => {
  const { tasks, subscriptionState } = useContext(LoggedInContext);
  const { toggleAddTaskForm } = useContext(DashboardContext);
  const filteredTasks = TaskHelper.filterActive(tasks);

  if (subscriptionState === 'SUBSCRIPTION_INACTIVE' && Object.keys(filteredTasks).length > 3) return <DayExpired />;
  if (Object.keys(tasks).length === 0) {
    return (
      <div className="day-main--noTasks">
        <button type="button" onClick={toggleAddTaskForm}>
          <img src={firstHabitIcon} alt="A sprout" />
          <h3>Add Your First Habit</h3>
        </button>
      </div>
    );
  }
  if (
    Object.values(tasks).every((task) => {
      const dayStatus = TaskHelper.dayStatus(task, displayDate);
      return ['inactive', 'archived', 'future', 'notCreated'].includes(dayStatus);
    })
  ) {
    return (
      <div className="day__main day-main__noTasksToday">
        <img src={relaxImg} alt="Relax" />
        <h2 className="day-main__noTasksTodayHeader">Sit back and relax</h2>
        <p className="day-main__noTasksTodaySubheader">You don’t have any habits set for today</p>
      </div>
    );
  }
  return (
    <div className="day__main">
      {TaskHelper.orderEntries(tasks).map(([taskId, task]) => {
        const dayStatus = TaskHelper.dayStatus(task, displayDate);
        if (['completed', 'incomplete', 'inactive'].includes(dayStatus))
          return <DayItem key={taskId} taskId={taskId} task={task} displayDate={displayDate} />;
        return null;
      })}
    </div>
  );
};

export default DayMain;
