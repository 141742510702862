import React, { useState } from 'react';
import axios from 'axios';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import HeaderBlue from '../components/HeaderBlue';
import Footer from '../components/Footer';

const ResetPassword = () => {
  const location = useLocation();
  const [password, setPassword] = useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  const handleSubmit = async () => {
    const { verify, user } = qs.parse(location.search);
    if (password !== passwordConfirmation) {
      setSuccess('');
      setError("The passwords don't match.");
    } else {
      const { data } = await axios.post(`/password/reset`, {
        token: verify,
        uid: user,
        password,
      });
      if (data.success) {
        setSuccess('Password reset successful.');
      } else {
        setError('This link has expired. Please request a new password reset.');
      }
    }
  };

  return (
    <>
      <HeaderBlue />
      <div className="login__wrapper">
        <div className="login__container">
          <h1>Password Reset</h1>
          {error && <div className="login__error">{error}</div>}
          {success && <div className="login__success">{success}</div>}
          <h3>Please enter your new password and confirm it.</h3>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="New Password"
          />
          <input
            type="password"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            placeholder="Password Confirmation"
          />
          <button className="login__submitButton" onClick={handleSubmit}>
            Reset Password
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResetPassword;
