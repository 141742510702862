import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderBlue from '../components/HeaderBlue';
import Footer from '../components/Footer';
import '../styles/Pricing.scss';
import Crown from '../assets/images/crown.svg';
import apiClient from '../services/apiClient';

const Pricing = () => {
  const [prices, setPrices] = useState({ month: 0, year: 0 });

  useEffect(() => {
    const fetchPrices = async () => {
      const { data } = await apiClient.getPrices();
      setPrices(data);
    };
    fetchPrices();
  }, []);

  return (
    <>
      <HeaderBlue />
      <div className="pricing__wrapper">
        <div className="pricing">
          <h1>Pricing</h1>

          <div className="pricing__module">
            <div className="pricing__moduleTop">
              <span>Free</span>
            </div>
            <div className="pricing__moduleBottom">
              <h2>$0</h2>
              <h3>/ forever</h3>
              <p>3 Free Habits</p>
              <Link to="/signup">
                <button className="blueButton">Get Started</button>
              </Link>
            </div>
          </div>

          <div className="pricing__module">
            <div className="pricing__moduleTop">
              <img src={Crown} alt="Crown" />
              <span>Premium</span>
            </div>
            <div className="pricing__moduleBottom">
              <div className="pricing__moduleBottom2Col">
                <div>
                  <h2>${(prices.month / 100).toFixed(2)}</h2>
                  <h3>/ month</h3>
                </div>
                <div>
                  <h2>${(prices.year / 100).toFixed(2)}</h2>
                  <h3>/ year</h3>
                </div>
              </div>
              <p>Unlimited Habits</p>
              <Link to="/signup">
                <button className="blueButton">Get Started</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
