import React, { ChangeEvent, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

import { reauthenticate } from '../services/authHelper';
import '../styles/DeleteModal.scss';
import exit from '../assets/images/exit.svg';

interface DeleteModalProps {
  user: firebase.User;
  toggleDeleteModal: () => void;
  handleSignOut: () => void;
}

const DeleteModal = ({ user, toggleDeleteModal, handleSignOut }: DeleteModalProps) => {
  const [formPassword, setFormPassword] = useState<string>('');
  const [formPasswordError, setFormPasswordError] = useState<boolean>(false);
  const signInProvider = firebase.auth().currentUser?.providerData[0]?.providerId;
  const isOAuth = signInProvider && ['facebook.com', 'google.com'].includes(signInProvider);

  const handleDelete = async () => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (!isOAuth && user.email) await firebase.auth().signInWithEmailAndPassword(user.email, formPassword);
      if (isOAuth) await reauthenticate(currentUser!);
      const firebaseToken = await currentUser!.getIdToken();
      const response = await axios.post('/user/delete', { firebaseToken });
      if (response.data.success) {
        handleSignOut();
      } else {
        console.log(response.data.message);
      }
    } catch (e) {
      setFormPasswordError(true);
      console.log(e.message);
    }
  };
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setFormPassword(e.target.value);
    setFormPasswordError(false);
  };

  return (
    <div
      className="form-modal"
      key={'delete-form'}
      onClick={(e) => {
        if ((e.target as Element).className === 'delete-form-wrapper') toggleDeleteModal();
      }}
    >
      <div className="delete-form-wrapper">
        <div className="delete-form">
          <button className="exitButton delete-form__exitButton" onClick={toggleDeleteModal}>
            <img src={exit} alt="Exit Icon" />
          </button>
          <h1>Delete Account</h1>
          <p>Once you delete your account, there's no getting it back.</p>
          <p>Are you sure you want to do this?</p>
          {!isOAuth && (
            <input
              type="password"
              placeholder="Confirm your password"
              value={formPassword}
              data-invalid={formPasswordError}
              onChange={handleInput}
            />
          )}
          <input type="text" placeholder="Tell us why you’re deleting..." />
          <div className="delete-form__buttons">
            <button className="whiteButton" onClick={toggleDeleteModal}>
              Cancel
            </button>
            <button className="redButton" onClick={handleDelete}>
              Yes, Delete Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
