import React, { useContext } from 'react';
import { LoggedInContext } from '../context';
import '../styles/DeleteTaskModal.scss';
import trashIcon from '../assets/images/trashIcon.svg';

interface DeleteTaskModalProps {
  task: Task;
  taskId: string;
  toggleDeleteTaskModal: (taskId: string | null) => void;
  deleteTask: (taskId: string) => void;
}

const DeleteTaskModal = ({ task, taskId, toggleDeleteTaskModal, deleteTask }: DeleteTaskModalProps) => {
  const { setNotification, setNotificationTask } = useContext(LoggedInContext);
  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as Element).className === 'delete-task-form__wrapper') toggleDeleteTaskModal(null);
  };
  const handleDeleteTask = () => {
    deleteTask(taskId);
    toggleDeleteTaskModal(null);
    setNotification(`${task.name} has been deleted.`);
    setNotificationTask(task);
  };

  return (
    <div className="form-modal" key={'delete-task-form'} onClick={handleClickOutside}>
      <div className="delete-task-form__wrapper">
        <div className="delete-task-form">
          <div className="delete-task-form__top">
            <div className="delete-task-form__topLeft">
              <img src={trashIcon} alt="Icon of a trash can" />
            </div>
            <div className="delete-task-form__topRight">
              <h1>Delete Habit</h1>
              <p>Are you sure you want to delete this habit?</p>
              <p>You won't be able to see your history.</p>
            </div>
          </div>
          <div className="delete-task-form__mid">
            <div
              className={task.notToDo ? 'color-indicator-cross' : 'color-indicator'}
              style={{ backgroundColor: task.color.value }}
            />
            <p>{task.name}</p>
          </div>
          <div className="delete-task-form__buttons">
            <button type="button" className="whiteButton" onClick={() => toggleDeleteTaskModal(null)}>
              Cancel
            </button>
            <button type="button" className="redButton" onClick={handleDeleteTask}>
              Yes, delete it.
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteTaskModal;
