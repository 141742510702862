import { useState, useEffect } from 'react';

const useRefresher = () => {
  const [mounted, setMounted] = useState(false);
  const refreshState = () => {
    setMounted(false);
    setTimeout(() => setMounted(true), 100);
  };
  useEffect(() => refreshState(), []);
  return {
    mounted,
    refreshState
  };
};

export default useRefresher;
