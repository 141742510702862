import React, { useState, useContext, ChangeEvent } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

import { UserContext } from '../context';

const SettingsPasswordForm = () => {
  const { handleSignIn } = useContext(UserContext);
  const [formActive, setFormActive] = useState<boolean>(false);
  const toggleForm = () => setFormActive((prev) => !prev);

  const [oldPassword, setOldPassword] = useState<string>('');
  const [oldPasswordError, setOldPasswordError] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordError, setNewPasswordError] = useState<boolean>(false);
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<string>('');
  const [newPasswordConfirmationError, setNewPasswordConfirmationError] = useState<boolean>(false);

  const signInProvider = firebase.auth().currentUser?.providerData[0]?.providerId;
  const isOAuth = signInProvider && ['facebook.com', 'google.com'].includes(signInProvider);

  const clearFields = () => {
    setOldPassword('');
    setOldPasswordError(false);
    setNewPassword('');
    setNewPasswordError(false);
    setNewPasswordConfirmation('');
    setNewPasswordConfirmationError(false);
  };
  const handleChangeOldPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
    if (e.target.value.length >= 6) setOldPasswordError(false);
  };
  const handleChangeNewPassword = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
    if (e.target.value.length >= 6) setNewPasswordError(false);
  };
  const handleChangeNewPasswordConfirmation = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPasswordConfirmation(e.target.value);
    if (e.target.value.length >= 6 && newPassword === e.target.value) setNewPasswordConfirmationError(false);
  };
  const handleSubmit = async () => {
    if (oldPassword.length < 6) return setOldPasswordError(true);
    if (newPassword.length < 6) return setNewPasswordError(true);
    if (newPassword !== newPasswordConfirmation || newPasswordConfirmation.length < 6)
      return setNewPasswordConfirmationError(true);
    const user = firebase.auth().currentUser;
    try {
      await firebase.auth().signInWithEmailAndPassword(user!.email!, oldPassword);
      await user!.updatePassword(newPassword);
      handleSignIn();
      clearFields();
      toggleForm();
    } catch (e) {
      setOldPasswordError(true);
    }
  };

  if (formActive) {
    return (
      <div className="settingsProfile__row">
        <label>Password</label>
        <div className="settingsProfile__form">
          <label>Old Password</label>
          <input
            type="password"
            value={oldPassword}
            onChange={handleChangeOldPassword}
            aria-invalid={oldPasswordError}
          />
          <label>New Password</label>
          <input
            type="password"
            value={newPassword}
            onChange={handleChangeNewPassword}
            aria-invalid={newPasswordError}
          />
          <label>Confirm New Password</label>
          <input
            type="password"
            value={newPasswordConfirmation}
            onChange={handleChangeNewPasswordConfirmation}
            aria-invalid={newPasswordConfirmationError}
          />
          <div className="settingsProfile__formButtons">
            <button className="whiteButton" onClick={toggleForm}>
              Cancel
            </button>
            {oldPasswordError || newPasswordError || newPasswordConfirmationError ? (
              <button className="blueButton blueButton--inactive">Save</button>
            ) : (
              <button className="blueButton" onClick={handleSubmit}>
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="settingsProfile__row">
        <label>Password</label>
        <h3>••••••••••</h3>
        {!isOAuth && (
          <button className="textOnlyButton" onClick={toggleForm}>
            Edit
          </button>
        )}
      </div>
    );
  }
};

export default SettingsPasswordForm;
