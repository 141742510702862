import React, { Dispatch, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import { ReactStripeElements, CardElement, injectStripe } from 'react-stripe-elements';

import creditCardIcons from '../assets/images/creditCards.svg';

interface UpdatePaymentFormProps {
  stripe?: ReactStripeElements.StripeProps;
  toggleUpdatePaymentForm: () => void;
  setSubscriptionNotification: Dispatch<string | null>;
}

const UpdatePaymentForm = ({
  stripe,
  toggleUpdatePaymentForm,
  setSubscriptionNotification,
}: UpdatePaymentFormProps) => {
  const [cardName, setCardName] = useState('');
  const [hasError, setHasError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmitPayment = async () => {
    setLoading(true);
    if (!cardName) {
      setHasError('Please input the name on the card.');
      return setLoading(false);
    }
    setHasError('');
    const { token } = await stripe!.createToken({ name: cardName });
    if (!token) return setLoading(false);
    const firebaseToken = await firebase.auth().currentUser!.getIdToken();
    const response = await axios.post('/subscription/update', {
      stripeToken: token.id,
      firebaseToken,
    });
    if (response.data.success) {
      setLoading(false);
      setSubscriptionNotification('Your payment information has been updated.');
      toggleUpdatePaymentForm();
    } else {
      setLoading(false);
      setSubscriptionNotification(
        `There was an error with updating your subscription. ${response.data.message} Please contact help@tryconfetti.io`
      );
      toggleUpdatePaymentForm();
    }
  };

  return (
    <div className="settingsSubscription">
      <h2>Subscription</h2>
      <div className="settingsSubscription__row">
        {loading && <div className="settingsSubscription__loadingLayer">Loading...</div>}
        <div className="settingsSubscription__paymentFormTop">
          <h3>Update Payment Method</h3>
          <img src={creditCardIcons} alt="Credit card logos" />
        </div>
        {hasError && <p className="error">{hasError}</p>}
        <input type="text" placeholder="Name on Card" value={cardName} onChange={(e) => setCardName(e.target.value)} />
        <CardElement style={{ base: { fontSize: '18px' } }} />
        <div className="settingsSubscription__formButtons">
          <button type="button" className="blueButton" onClick={handleSubmitPayment}>
            Update Payment
          </button>
          <button type="button" className="whiteButton" onClick={toggleUpdatePaymentForm}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default injectStripe(UpdatePaymentForm);
