import React, { useContext } from 'react';
import { DashboardContext, LoggedInContext } from '../context';
import '../styles/ArchiveTaskModal.scss';
import archiveIcon from '../assets/images/archiveIcon.svg';

interface ArchiveTaskModalProps {
  task: Task;
  taskId: string;
  archiveTask: (taskId: string) => void;
}

const ArchiveTaskModal = ({ task, taskId, archiveTask }: ArchiveTaskModalProps) => {
  const { toggleArchiveTaskModal } = useContext(DashboardContext);
  const { setNotification, setNotificationTask } = useContext(LoggedInContext);
  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as Element).className === 'archive-task-form__wrapper') toggleArchiveTaskModal(null);
  };
  const handleArchiveTask = () => {
    archiveTask(taskId);
    toggleArchiveTaskModal(null);
    setNotification(`${task.name} has been archived.`);
    setNotificationTask(task);
  };

  return (
    <div className="form-modal" key={'archive-task-form'} onClick={handleClickOutside}>
      <div className="archive-task-form__wrapper">
        <div className="archive-task-form">
          <div className="archive-task-form__top">
            <div className="archive-task-form__topLeft">
              <img src={archiveIcon} alt="Icon of a trash can" />
            </div>
            <div className="archive-task-form__topRight">
              <h1>Archive Habit</h1>
              <p>Are you sure you want to archive this habit?</p>
              <p>Your history will still be displayed.</p>
            </div>
          </div>
          <div className="archive-task-form__mid">
            <div
              className={task.notToDo ? 'color-indicator-cross' : 'color-indicator'}
              style={{ backgroundColor: task.color.value }}
            />
            <p>{task.name}</p>
          </div>
          <div className="archive-task-form__buttons">
            <button type="button" className="whiteButton" onClick={() => toggleArchiveTaskModal(null)}>
              Cancel
            </button>
            <button type="button" className="blueButton" onClick={handleArchiveTask}>
              Yes, archive it.
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchiveTaskModal;
