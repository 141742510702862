import firebase from 'firebase/app';
import 'firebase/auth';

interface HandleSignInProps {
  success: () => void;
  error: (err: firebase.FirebaseError) => void;
}

export const handleGoogleSignIn = ({ success, error }: HandleSignInProps) => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('email');
  firebase.auth().useDeviceLanguage();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(async (response) => {
      // @ts-ignore
      const responseName = response.additionalUserInfo?.profile?.name;
      const isNewUser = response.additionalUserInfo?.isNewUser;
      if (isNewUser) {
        await response.user?.updateProfile({ displayName: responseName });
        success();
      } else {
        success();
      }
    })
    .catch((err) => {
      error(err);
    });
};

export const handleFacebookSignIn = ({ success, error }: HandleSignInProps) => {
  const provider = new firebase.auth.FacebookAuthProvider();
  provider.addScope('email');
  firebase.auth().useDeviceLanguage();
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(async (response) => {
      // @ts-ignore
      const responseName = response.additionalUserInfo?.profile?.name;
      const isNewUser = response.additionalUserInfo?.isNewUser;
      if (isNewUser) {
        await response.user?.updateProfile({ displayName: responseName });
        success();
      } else {
        success();
      }
    })
    .catch((err) => {
      error(err);
    });
};

export const handleAppleSignIn = ({ success, error }: HandleSignInProps) => {
  const provider = new firebase.auth.OAuthProvider('apple.com');
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(async (response) => {
      // @ts-ignore
      const responseName = response.additionalUserInfo?.profile?.name;
      const isNewUser = response.additionalUserInfo?.isNewUser;
      if (isNewUser) {
        await response.user?.updateProfile({ displayName: responseName });
        success();
      } else {
        success();
      }
    })
    .catch((err) => {
      error(err);
    });
};
