import confetti from 'canvas-confetti';
import colors from '../config/colors';

const ConfettiHelper = {
  celebrate: () => {
    confetti({
      particleCount: 200,
      spread: 60,
      startVelocity: 90,
      ticks: 500,
      colors: colors.map((color) => color.value),
    });
  },
};
export default ConfettiHelper;
