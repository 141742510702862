import React, { useContext, useState } from 'react';
import { format, sub, add, startOfDay, isSameDay } from 'date-fns';

import { DashboardContext, LoggedInContext } from '../context';
import DateSelectors from '../components/DateSelectors';
import BedtimePrompt from '../components/BedtimePrompt';
import DayMain from '../components/DayMain';
import ChromePrompt from '../components/ChromePrompt';
import plus from '../assets/images/plus.svg';
import '../styles/Day.scss';

const Day = () => {
  const { userSettings } = useContext(LoggedInContext);
  const { toggleAddTaskForm } = useContext(DashboardContext);

  const today = startOfDay(new Date());
  const startingDate = userSettings.createdAt.toDate();
  const [displayDate, setDisplayDate] = useState(today);
  const goToToday = () => setDisplayDate(today);
  const previousDay = () => !isSameDay(displayDate, startingDate) && setDisplayDate(sub(displayDate, { days: 1 }));
  const nextDay = () => !isSameDay(displayDate, today) && setDisplayDate(add(displayDate, { days: 1 }));

  return (
    <div className="day__container">
      <div className="day__top">
        <h2 className="day-top__dateHeader">{format(displayDate, 'E, MMM d')}</h2>
        {isSameDay(displayDate, today) || (
          <button className="textOnlyButton" onClick={goToToday}>
            Today
          </button>
        )}
        <DateSelectors
          leftArrowAction={previousDay}
          rightArrowAction={nextDay}
          backInactive={isSameDay(displayDate, startingDate)}
          forwardInactive={isSameDay(displayDate, today)}
        />
      </div>
      {!userSettings.bedtimeHour && <BedtimePrompt />}
      <DayMain displayDate={displayDate} />
      <ChromePrompt />

      <div className="day-addTask--mobile">
        <button className="whiteButton" onClick={toggleAddTaskForm}>
          <img src={plus} alt="Icon of a plus sign" /> Add Habit
        </button>
      </div>
    </div>
  );
};

export default Day;
