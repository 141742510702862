import React, { useState, useContext } from 'react';
import { format } from 'date-fns';

import { DashboardContext, LoggedInContext } from '../context';
import { useTasks } from '../hooks';
import TaskHelper from '../services/TaskHelper';
import verticalDotsWhite from '../assets/images/verticalDots-white.svg';
import verticalDotsGrey from '../assets/images/verticalDots-grey.svg';
import streakIcon from '../assets/images/streakIcon.svg';
import streakIconRed from '../assets/images/streakIconRed.svg';
import check from '../assets/images/check.svg';
import '../styles/DayItem.scss';

interface DayItemProps {
  task: Task;
  taskId: string;
  displayDate: Date;
}

const DayItem = ({ task, taskId, displayDate }: DayItemProps) => {
  const { name, notToDo, color } = task;
  const { setNotification, setNotificationTask } = useContext(LoggedInContext);
  const { toggleTask, toggleEditTaskForm, toggleDeleteTaskModal, toggleArchiveTaskModal } = useContext(
    DashboardContext
  );
  const { unarchiveTask } = useTasks();
  const status = TaskHelper.dayStatus(task, displayDate);
  const streak = TaskHelper.streak(task);
  const isComplete = status === 'completed';
  const isInactive = status === 'inactive';

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

  const inactiveColor = '#BED1ED';
  const backgroundColor = { backgroundColor: isInactive ? inactiveColor : color.value };

  const handleUnarchiveTask = () => {
    setNotification(`${task.name} has been unarchived.`);
    setNotificationTask(task);
    unarchiveTask(taskId);
  };

  const renderSubmitSection = () => {
    if (status === 'completed')
      return (
        <div className="dayItem__submit">
          <h1 className="dayItem__completed">
            <img src={check} alt="Check mark" /> {notToDo ? 'Avoided' : 'Completed'}
          </h1>
          <button
            type="button"
            className="dayItem__undoButton"
            onClick={(e) => toggleTask(task, taskId, displayDate, e)}
          >
            Undo
          </button>
        </div>
      );
    if (status === 'incomplete')
      return (
        <div className="dayItem__submit">
          <button
            type="button"
            className="dayItem__completeButton"
            onClick={(e) => toggleTask(task, taskId, displayDate, e)}
          >
            {notToDo ? 'I Avoided This' : 'Mark Complete'}
          </button>
        </div>
      );
    if (status === 'inactive')
      return (
        <div className="dayItem__submit">
          <h1 className="dayItem__inactive">Inactive on {format(displayDate, 'EEEE')}</h1>
        </div>
      );
    return null;
  };

  return (
    <div className="dayItem" data-status={status} onMouseLeave={() => setIsDropdownOpen(false)}>
      <div className="dayItem__highlight" style={backgroundColor} />
      <div className="dayItem__leftSide">
        <div className={notToDo ? 'color-indicator-cross' : 'color-indicator'} style={backgroundColor} />
      </div>
      <div className="dayItem__topLeft">
        <h2 className="dayItem__header">{name}</h2>
      </div>
      <div className="dayItem__topRight" onClick={toggleDropdown}>
        {streak.count > 0 && (
          <div className="dayItem__streak" data-active={isComplete}>
            <p>{streak.count}</p>
            <img src={isComplete ? streakIcon : streakIconRed} alt="A fire indicating a streak" />
          </div>
        )}
        <img src={isComplete ? verticalDotsWhite : verticalDotsGrey} alt="Three vertical dots indicating a menu" />
      </div>
      {isDropdownOpen && (
        <div className="dayItem__dropDown">
          <ul>
            <li onClick={() => toggleEditTaskForm(taskId)}>Edit Habit</li>
            {task.dateInactive ? (
              <li onClick={handleUnarchiveTask}>Unarchive Habit</li>
            ) : (
              <li onClick={() => toggleArchiveTaskModal(taskId)}>Archive Habit</li>
            )}
            <li onClick={() => toggleDeleteTaskModal(taskId)}>Delete Habit</li>
          </ul>
        </div>
      )}
      {renderSubmitSection()}
    </div>
  );
};
export default DayItem;
