import React, { useState, useContext } from 'react';
import { getDaysInMonth, getISODay, add, format } from 'date-fns';

import TaskHelper from '../services/TaskHelper';
import { LoggedInContext } from '../context';
import MonthPopup from './MonthPopup';

const MonthCalendar = ({ monthStart }: { monthStart: Date }) => {
  const { tasks } = useContext(LoggedInContext);
  const [popupActive, setPopupActive] = useState<Date | null>(null);

  const daysInMonth = getDaysInMonth(monthStart);
  const weekdayOffset = getISODay(monthStart) - 1;
  const remainderDays = 7 - ((daysInMonth + weekdayOffset) % 7);
  const singleMonthArray = [
    ...(weekdayOffset ? Array(weekdayOffset) : []),
    ...Array(daysInMonth).keys(),
    ...(remainderDays ? Array(remainderDays) : []),
  ];

  return (
    <div className="month__main">
      <div className="month__calendar">
        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((dayNumber) => (
          <div className="month__calendarHeader" key={dayNumber}>
            {dayNumber}
          </div>
        ))}
        {singleMonthArray.map((dayOffset, index) => {
          const taskDate = add(monthStart, { days: dayOffset });
          if (dayOffset === undefined) return <div className="month__calendarItem" key={index} />;
          const goalPercentage = TaskHelper.dailyGoal(tasks, taskDate);
          return (
            <div
              className="month__calendarItem"
              key={format(taskDate, 'yyyy-MM-dd')}
              onMouseEnter={() => setPopupActive(taskDate)}
              onMouseLeave={() => setPopupActive(null)}
            >
              <MonthPopup taskDate={taskDate} popupActive={popupActive} />
              {goalPercentage === null ? (
                // eslint-disable-next-line
                <h2 className="month__calendarItem__goal" />
              ) : (
                <h2 className="month__calendarItem__goal">{Math.round(goalPercentage * 100)}%</h2>
              )}
              <h3>{format(taskDate, 'd')}</h3>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MonthCalendar;
