import React, { useState } from 'react';
import axios from 'axios';
import Lottie from 'react-lottie';
import firebase from 'firebase/app';
import 'firebase/auth';
import { format } from 'date-fns';

import loadingJSON from '../assets/lottie/loading.json';
import exit from '../assets/images/exit.svg';
import '../styles/CancelSubscriptionModal.scss';

interface CancelSubscriptionModalProps {
  userSettings: UserSettings;
  toggleCancelSubscriptionModal: () => void;
}

const CancelSubscriptionModal = ({ userSettings, toggleCancelSubscriptionModal }: CancelSubscriptionModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean | null>(null);

  const handleCancelSubscription = async () => {
    setLoading(true);
    const firebaseToken = await firebase.auth().currentUser!.getIdToken();
    const response = await axios.post('/subscription/cancel', { firebaseToken });
    if (response.data.success) {
      setLoading(false);
      setSuccess(true);
    } else {
      // Need error handling
      setLoading(false);
      setSuccess(true);
    }
  };
  const renderLoadingMessage = () => {
    return (
      <div className="cancel-subscription-modal">
        <Lottie options={{ loop: true, autoplay: true, animationData: loadingJSON }} width={250} height={200} />
      </div>
    );
  };
  const renderFailureMessage = () => {
    return (
      <div className="cancel-subscription-modal cancel-subscription-modal--success">
        <h1>Cancel Subscription</h1>
        <p>
          There was an error with your subscription cancellation. Please contact us as{' '}
          <a href="mailto:help@tryconfetti.io">help@tryconfetti.io</a>
        </p>
        <button className="whiteButton" onClick={toggleCancelSubscriptionModal}>
          Close
        </button>
      </div>
    );
  };
  const renderSuccessMessage = () => {
    return (
      <div className="cancel-subscription-modal cancel-subscription-modal--success">
        <h1>Subscription Cancelled</h1>
        <p>Your subscription will end on {format(userSettings.subscriptionEnd!.toDate(), 'PP')}</p>
        <button className="whiteButton" onClick={toggleCancelSubscriptionModal}>
          Close
        </button>
      </div>
    );
  };
  const renderForm = () => {
    return (
      <div className="cancel-subscription-modal">
        <button className="exitButton cancel-subscription-modal__exitButton" onClick={toggleCancelSubscriptionModal}>
          <img src={exit} alt="Exit icon" />
        </button>
        <div className="cancel-subscription-modal__top">
          <h1>Cancel Subscription</h1>
          <p>
            Are you sure you want to cancel? You won't be able to access your dashboard after your current{' '}
            {userSettings.subscriptionStatus === 'trialing' ? 'trial' : 'subscription'} expires on{' '}
            {format(userSettings.subscriptionEnd!.toDate(), 'PP')}.
          </p>
          <input type="text" placeholder="Tell us why you’re cancelling..." />
        </div>
        <div className="cancel-subscription-modal__buttons">
          <button className="whiteButton" onClick={toggleCancelSubscriptionModal}>
            Cancel
          </button>
          <button className="redButton" onClick={handleCancelSubscription}>
            Yes, Cancel Subscription
          </button>
        </div>
      </div>
    );
  };
  return (
    <div
      className="form-modal"
      key={'cancel-subscription-modal'}
      onClick={(e) => {
        if ((e.target as Element).className === 'cancel-subscription-modal__wrapper') toggleCancelSubscriptionModal();
      }}
    >
      <div className="cancel-subscription-modal__wrapper">
        {success
          ? renderSuccessMessage()
          : success === false && loading === false
          ? renderFailureMessage()
          : loading
          ? renderLoadingMessage()
          : renderForm()}
      </div>
    </div>
  );
};

export default CancelSubscriptionModal;
