import React, { useContext } from 'react';
import { add, format, isSameDay, isBefore } from 'date-fns';

import { LoggedInContext } from '../context';
import TaskHelper from '../services/TaskHelper';
import useYearNavigation from '../hooks/useYearNavigation';
import useRefresher from '../hooks/useRefresher';
import DateSelectors from '../components/DateSelectors';
import ViewSelector from '../components/ViewSelector';
import OverallProgressBar from '../components/OverallProgressBar';
import DashboardProgressBar from '../components/DashboardProgressBar';
import '../styles/Year.scss';

const Year = () => {
  const { tasks, userSettings } = useContext(LoggedInContext);
  const {
    defaultStart,
    yearStart,
    handleLastYear,
    lastYear,
    currentYear,
    handleCurrentYear,
    handleNextYear,
  } = useYearNavigation();
  const { mounted, refreshState } = useRefresher();
  const userCreatedDate = userSettings.createdAt.toDate();
  const isCurrentYear = isSameDay(yearStart, currentYear);
  const isFirstYear = isBefore(yearStart, userCreatedDate) && !isCurrentYear;

  const yearlyGoal = TaskHelper.yearlyGoal(tasks, yearStart);
  const progressComparison = yearlyGoal - TaskHelper.yearlyGoal(tasks, lastYear);
  const yearlyGoalPercentage = Math.round(yearlyGoal * 100);

  const yearHeading = isSameDay(yearStart, defaultStart)
    ? `${format(yearStart, 'PP')} - Today`
    : `January - December ${format(yearStart, 'yyyy')}`;
  const filteredTasks = TaskHelper.filter(tasks, yearStart, add(yearStart, { years: 1 }));

  return (
    <div className="year">
      <div className="main-top">
        <DateSelectors leftArrowAction={handleLastYear} rightArrowAction={handleNextYear} backInactive={isFirstYear} />
        <h1 className="main-top-header">{yearHeading}</h1>
        <div className="main-top__left">
          {!isSameDay(defaultStart, yearStart) && (
            <button type="button" className="textOnlyButton" onClick={handleCurrentYear}>
              Current
            </button>
          )}
          <ViewSelector summaryViewOnly refreshState={refreshState} />
        </div>
      </div>
      <OverallProgressBar width={yearlyGoalPercentage} progressComparison={progressComparison} timePeriod="year" />
      <div className="year__main">
        {TaskHelper.orderEntries(filteredTasks).map(([taskId, task]) => {
          const { numerator, denominator } = TaskHelper.yearlyStats(task, yearStart);
          return (
            <DashboardProgressBar
              key={taskId}
              task={task}
              numerator={numerator}
              denominator={denominator}
              mounted={mounted}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Year;
