import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import { LoggedInContext } from '../context';
import '../styles/EmailConfirmationNotification.scss';

const EmailConfirmationNotification = () => {
  const { user } = useContext(LoggedInContext);
  const [isVerified, setIsVerified] = useState<boolean>(user.emailVerified);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    setIsVerified(user.emailVerified);
  }, [user]);

  const handleSubmit = async () => {
    const { email } = user;
    await axios.post(`/email/resend_email_verification`, { email });
    setEmailSent(true);
  };

  if (isVerified) return null;
  return (
    <div className="emailConfirmationNotification">
      <div className="emailConfirmationNotification__container">
        <p>
          <span>Please verify your email. </span>
          Didn&apos;t receive anything?
        </p>
        {emailSent ? (
          <button type="button" disabled>
            Email Sent!
          </button>
        ) : (
          <button type="button" onClick={handleSubmit}>
            Resend confirmation
          </button>
        )}
      </div>
    </div>
  );
};

export default EmailConfirmationNotification;
