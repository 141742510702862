import React from 'react';
import '../styles/IndexAnimation.scss';

interface HabitBubbleProps {
  name: string;
  color: string;
  notToDo: boolean;
  offsetY: number;
  animationDelay: string;
}

const habitList: Omit<HabitBubbleProps, 'container'>[] = [
  { name: '⏰ Wake up early', notToDo: false, color: '#9c5cff', offsetY: 0, animationDelay: '0s' },
  { name: '✏️ Journal', notToDo: false, color: '#4368ff', offsetY: -90, animationDelay: '1s' },
  { name: '📘 Read', notToDo: false, color: '#07dfe0', offsetY: 90, animationDelay: '2.5s' },
  { name: '☯️  Meditate', notToDo: false, color: '#ffcd45', offsetY: -70, animationDelay: '5s' },
  { name: '💬 Learn a language', notToDo: false, color: '#07dfe0', offsetY: -160, animationDelay: '3s' },
  { name: '🌍 Learn something new', notToDo: false, color: '#ffa031', offsetY: 180, animationDelay: '8.2s' },
  { name: '✒️  Draw', notToDo: false, color: '#fd4ec7', offsetY: 180, animationDelay: '-1s' },
  { name: '🎸 Guitar', notToDo: false, color: '#ffa031', offsetY: 180, animationDelay: '-4s' },
  { name: '🦷 Floss', notToDo: false, color: '#3798fa', offsetY: -30, animationDelay: '-3.8s' },
  { name: '🌳 Go outside', notToDo: false, color: '#ffcd45', offsetY: -70, animationDelay: '-8s' },
  { name: '🎧 Educational podcast', notToDo: false, color: '#9c5cff', offsetY: 70, animationDelay: '-4.2s' },
  { name: '📝 Do homework', notToDo: false, color: '#18de74', offsetY: 90, animationDelay: '-0.5s' },
  { name: '🧘 Yoga', notToDo: false, color: '#9c5cff', offsetY: 50, animationDelay: '-15s' },
  { name: '🙏 Pray', notToDo: false, color: '#ffa031', offsetY: -100, animationDelay: '-5s' },
  { name: '🏃‍♀️ Go for a jog', notToDo: false, color: '#fd4ec7', offsetY: 0, animationDelay: '-17.5s' },
  { name: '🥵 Cardio', notToDo: false, color: '#ffcd45', offsetY: -100, animationDelay: '-14s' },
  { name: '📝 Study', notToDo: false, color: '#ff5858', offsetY: -70, animationDelay: '-17s' },
  { name: '😀 Smile', notToDo: false, color: '#ff5858', offsetY: 100, animationDelay: '-19s' },
  { name: '🎷 Learn an instrument', notToDo: false, color: '#18de74', offsetY: -150, animationDelay: '-16.4s' },
  { name: '😴 20 min nap', notToDo: false, color: '#4368ff', offsetY: -170, animationDelay: '-13s' },
  { name: '🍩 Junk food', notToDo: true, color: '#ff5858', offsetY: -185, animationDelay: '-2.5s' },
  { name: '🐦 Twitter', notToDo: true, color: '#ffa031', offsetY: -0, animationDelay: '-9s' },
  { name: '☕ Caffeine', notToDo: true, color: '#07dfe0', offsetY: -150, animationDelay: '-9.2s' },
  { name: '🎰 Gambling', notToDo: true, color: '#4368ff', offsetY: 150, animationDelay: '-12.6s' },
  { name: '🚯 Littering', notToDo: true, color: '#18de74', offsetY: 0, animationDelay: '4s' },
  { name: '▶️ YouTube', notToDo: true, color: '#fd4ec7', offsetY: -30, animationDelay: '-12s' },
  { name: '🎮 Video Games', notToDo: true, color: '#ff5858', offsetY: 90, animationDelay: '-10s' },
  { name: '📵 Phone before bed', notToDo: true, color: '#33c0ff', offsetY: 175, animationDelay: '-8s' },
  { name: '🎬 Netflix', notToDo: true, color: '#ffa031', offsetY: -100, animationDelay: '-2s' },
];

const HabitBubble = ({ name, notToDo, color, offsetY, animationDelay }: HabitBubbleProps) => {
  const clientHeight = 500;
  const offsetTop = (clientHeight || 0) / 2;
  const randomOption = Math.floor(Math.random() * 6);
  return (
    <div
      className="habitBubble"
      data-option={randomOption}
      style={{
        animationDelay,
        top: offsetTop + offsetY,
      }}
    >
      <div className={notToDo ? 'color-indicator-cross' : 'color-indicator'} style={{ backgroundColor: color }} />
      <span>{name}</span>
    </div>
  );
};

const IndexAnimation = () => {
  return (
    <div className="indexAnimation__container">
      {habitList.map((habit) => (
        <HabitBubble key={habit.name} {...habit} />
      ))}
    </div>
  );
};

export default IndexAnimation;
