import React, { useState, useContext, Dispatch } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import { format } from 'date-fns';

import { LoggedInContext } from '../context';
import '../styles/SettingsSubscription.scss';

interface ChangePlanFormProps {
  toggleChangePlanForm: () => void;
  toggleCancelSubscriptionModal: () => void;
  setSubscriptionNotification: Dispatch<string>;
  prices: { month: number; year: number };
}

const ChangePlanForm = ({
  prices,
  toggleChangePlanForm,
  toggleCancelSubscriptionModal,
  setSubscriptionNotification,
}: ChangePlanFormProps) => {
  const { userSettings } = useContext(LoggedInContext);
  const { subscriptionFrequency, subscriptionEnd } = userSettings;
  const [loading, setLoading] = useState(false);
  const [selectedFrequency, setSelectedFrequency] = useState(subscriptionFrequency);

  const handleSubmit = async () => {
    setLoading(true);
    const firebaseToken = await firebase.auth().currentUser!.getIdToken();
    const response = await axios.post('/subscription/change_plan', {
      paymentFrequency: selectedFrequency,
      firebaseToken,
    });
    if (response.data.success) {
      setLoading(false);
      setSubscriptionNotification('Your subscription plan has been updated.');
      toggleChangePlanForm();
    } else {
      setLoading(false);
      setSubscriptionNotification(`There's been an error with updating your subscription. ${response.data.message}`);
      toggleChangePlanForm();
    }
  };

  return (
    <div className="settingsSubscription">
      <h2>Subscription</h2>
      <div className="settingsSubscription__row">
        {loading && <div className="settingsSubscription__loadingLayer">Loading...</div>}
        <h3>Select Plan</h3>
        <p>
          Your plan will be updated for your next billing period, starting on {format(subscriptionEnd!.toDate(), 'LL')}.
        </p>

        <div
          className="settingsSubscription__plan"
          aria-selected={selectedFrequency === 'month'}
          onClick={() => setSelectedFrequency('month')}
        >
          <div className="settingsSubscription__planLeft">
            <h4>
              <strong>${(prices.month / 100).toFixed(2)}</strong> / billed monthly
            </h4>
            <p>Unlimited access to all features in Confetti</p>
          </div>
          {selectedFrequency === 'month' ? (
            <button className="blueButton">Selected</button>
          ) : (
            <button className="whiteButton">Select</button>
          )}
        </div>

        <div
          className="settingsSubscription__plan"
          aria-selected={selectedFrequency === 'year'}
          onClick={() => setSelectedFrequency('year')}
        >
          <div className="settingsSubscription__planLeft">
            <h4>
              <strong>${(prices.year / 100).toFixed(2)}</strong> / billed yearly
            </h4>
            <p>Unlimited access to all features in Confetti</p>
          </div>
          {selectedFrequency === 'year' ? (
            <button className="blueButton">Selected</button>
          ) : (
            <button className="whiteButton">Select</button>
          )}
        </div>

        <div className="settingsSubscription__planButtons">
          <button className="textOnlyButton" onClick={toggleCancelSubscriptionModal}>
            Cancel Subscription
          </button>
          {selectedFrequency !== subscriptionFrequency ? (
            <button className="blueButton" onClick={handleSubmit}>
              Save Subscription
            </button>
          ) : (
            <button className="blueButton" onClick={toggleChangePlanForm}>
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangePlanForm;
