import { useState, Dispatch } from 'react';
import { sub, add, startOfYear, isSameDay } from 'date-fns';

export interface YearNavigation {
  defaultStart: Date;
  yearStart: Date;
  setYearStart: Dispatch<Date>;
  lastYear: Date;
  handleLastYear: () => void;
  currentYear: Date;
  handleCurrentYear: () => void;
  nextYear: Date;
  handleNextYear: () => void;
}

const useYearNavigation = (): YearNavigation => {
  const defaultStart = add(sub(new Date(), { years: 1 }), { days: 1 });
  const [yearStart, setYearStart] = useState(defaultStart);

  const currentYear = startOfYear(new Date());
  const lastYear = () => {
    if (isSameDay(yearStart, defaultStart)) return sub(new Date(currentYear), { years: 1 });
    if (isSameDay(yearStart, currentYear)) return defaultStart;
    return sub(new Date(yearStart), { years: 1 });
  };
  const nextYear = () => {
    if (isSameDay(yearStart, sub(new Date(currentYear), { years: 1 }))) return defaultStart;
    if (isSameDay(yearStart, defaultStart)) return currentYear;
    return add(new Date(yearStart), { years: 1 });
  };

  const handleLastYear = () => setYearStart(lastYear());
  const handleCurrentYear = () => setYearStart(defaultStart);
  const handleNextYear = () => setYearStart(nextYear());

  return {
    defaultStart,
    yearStart,
    setYearStart,
    lastYear: lastYear(),
    handleLastYear,
    nextYear: nextYear(),
    handleNextYear,
    currentYear,
    handleCurrentYear,
  };
};

export default useYearNavigation;
