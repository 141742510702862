import React, { useContext } from 'react';
import setBedtimeIcon from '../assets/images/setBedtimeIcon.svg';
import { DashboardContext } from '../context';

const BedtimePrompt = () => {
  const { toggleBedtimeModal } = useContext(DashboardContext);
  return (
    <div className="day-main--noBedtime">
      <button onClick={toggleBedtimeModal}>
        <img src={setBedtimeIcon} alt="Bedtime icon" />
        <h3>Add Your Bedtime</h3>
      </button>
    </div>
  );
};

export default BedtimePrompt;
