import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/images/logo.svg';
import heartIcon from '../assets/images/heartIcon.svg';
import LinkedInIcon from '../assets/images/LinkedInIcon.svg';
import twitterIcon from '../assets/images/twitterIcon.svg';
import '../styles/Footer.scss';

const Footer = () => (
  <footer className="footer">
    <div className="footer__container">
      <div className="footer__left">
        <img src={logo} alt="Confetti Logo" />
        <p className="footer__copyright">Copyright &copy; {new Date().getFullYear()}</p>
        <p className="footer__madeWith">
          Made with <img src={heartIcon} alt="Heart icon" /> by
          <a href="https://www.linkedin.com/in/danny-sapio-035481108/" target="_blank" rel="noopener noreferrer">
            {' '}
            Danny
          </a>{' '}
          &amp;
          <a href="https://www.wilsonplau.com" target="_blank" rel="noopener noreferrer">
            {' '}
            Wilson
          </a>
        </p>
      </div>

      <div className="footer__right">
        <div className="footer__rightTopLeft">
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/terms">Terms of Service</Link>
          <a href="mailto:hello@tryconfetti.io">Email Us</a>
        </div>
        <div className="footer__rightTopRight">
          <a href="https://chrome.google.com/webstore/detail/confetti-habit-tracker/hicbenbldlkghoodigniooplopgjjpnh">
            Chrome Extension
          </a>
          <a href="https://apps.apple.com/us/app/confetti-habits/id1507853418">iOS App</a>
          <a href="https://play.google.com/store/apps/details?id=com.confettimobile">Android App</a>
        </div>
        <div className="footer__rightBottom">
          <a href="https://twitter.com/confettihabits" target="_blank" rel="noopener noreferrer">
            <img src={twitterIcon} alt="Twitter icon" />
          </a>
          <a href="https://www.linkedin.com/company/confettihabits" target="_blank" rel="noopener noreferrer">
            <img src={LinkedInIcon} alt="LinkedIn icon" />
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
