import { useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { LoggedInContext } from '../context';

const useHandleUpgrade = () => {
  const { user, togglePaymentModal } = useContext(LoggedInContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const { upgrade } = qs.parse(location.search);
    if (upgrade && user) togglePaymentModal();
    if (upgrade && !user) history.push('./login');
  }, [location, user, togglePaymentModal, history]);
  return null;
};

export default useHandleUpgrade;
