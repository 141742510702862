import {
  isBefore,
  isAfter,
  format,
  getDay,
  add,
  sub,
  getDaysInMonth,
  getDaysInYear,
  startOfDay,
  isSameDay,
} from 'date-fns';

const TaskHelper = {
  dayStatus: (task: Task, taskDate: Date): DayStatus => {
    const dateString = format(taskDate, 'yyyy-MM-dd');
    if (task.record?.[dateString]) return task.record[dateString];
    if (isBefore(taskDate, startOfDay(task.dateCreated.toDate()))) {
      return 'notCreated';
    } else if (
      task.dateInactive &&
      (isAfter(taskDate, task.dateInactive.toDate()) || isSameDay(taskDate, task.dateInactive.toDate()))
    ) {
      return 'archived';
    } else if (task.history[dateString]) {
      return 'completed';
    } else if (!task.weeklyFrequency[getDay(taskDate)]) {
      return 'inactive';
    } else if (isBefore(new Date(), taskDate)) {
      return 'future';
    } else {
      return 'incomplete';
    }
  },
  streak: (task: Task): Streak => {
    if (!task) return { count: 0, endDate: null };
    let streak = 0;
    let streakBroken = false;
    let dayCounter = 0;
    let lastStreakDate = startOfDay(new Date());
    if (Object.values(task.weeklyFrequency).every((val) => !val)) return { count: 0, endDate: null };
    while (!streakBroken) {
      const checkDate = sub(startOfDay(new Date()), { days: dayCounter });
      const formatedDate = format(checkDate, 'yyyy-MM-dd');
      const dayNumber = getDay(checkDate);
      if (task.history[formatedDate]) {
        lastStreakDate = checkDate;
        streak += 1;
        dayCounter += 1;
      } else if (!task.weeklyFrequency[dayNumber]) {
        dayCounter += 1;
      } else if (dayCounter === 0) {
        lastStreakDate = checkDate;
        dayCounter += 1;
      } else {
        streakBroken = true;
        return { count: streak, endDate: lastStreakDate };
      }
    }
    return { count: 0, endDate: null };
  },
  orderEntries: (tasks: Tasks): [string, Task][] => {
    return Object.entries(tasks).sort(([_taskAId, taskA], [_taskBId, taskB]) => {
      if (taskA.dateCreated.toDate() < taskB.dateCreated.toDate()) return -1;
      if (taskA.dateCreated.toDate() > taskB.dateCreated.toDate()) return 1;
      return 0;
    });
  },
  filter: (tasks: Tasks, startDate: Date, endDate: Date): Tasks => {
    const filteredTasks: Tasks = {};
    for (const [taskId, task] of Object.entries(tasks)) {
      if (
        // was created before the last day of the period (+1 to make it inclusive)
        isBefore(task.dateCreated.toDate(), endDate) &&
        (!task.dateInactive || isBefore(startDate, startOfDay(task.dateInactive.toDate())))
      ) {
        filteredTasks[taskId] = task;
      }
    }
    return filteredTasks;
  },
  filterActive: (tasks: Tasks) => {
    const filteredTasks: Tasks = {};
    for (const [taskId, task] of Object.entries(tasks)) {
      if (!task.dateInactive) filteredTasks[taskId] = task;
    }
    return filteredTasks;
  },
  dailyGoal: (tasks: Tasks, taskDate: Date) => {
    let numerator = 0;
    let denominator = 0;
    Object.values(tasks).forEach((task) => {
      const dayStatus = TaskHelper.dayStatus(task, taskDate);
      if (['completed'].includes(dayStatus)) numerator += 1;
      if (['completed', 'incomplete'].includes(dayStatus)) denominator += 1;
    });
    return denominator ? numerator / denominator : null;
  },
  weeklyStats: (task: Task, startDate: Date) => {
    let numerator = 0;
    let denominator = 0;
    [0, 1, 2, 3, 4, 5, 6].forEach((dayOffset) => {
      const taskDate = add(startDate, { days: dayOffset });
      const dayStatus = TaskHelper.dayStatus(task, taskDate);
      if (['completed'].includes(dayStatus)) numerator += 1;
      if (['completed', 'incomplete'].includes(dayStatus)) denominator += 1;
    });
    return { numerator, denominator };
  },
  weeklyGoal: (tasks: Tasks, startDate: Date) => {
    let numerator = 0;
    let denominator = 0;
    Object.values(tasks).forEach((task) => {
      const stats = TaskHelper.weeklyStats(task, startDate);
      numerator += stats.numerator;
      denominator += stats.denominator;
    });
    return denominator ? numerator / denominator : 0;
  },
  monthlyStats: (task: Task, startDate: Date) => {
    let numerator = 0;
    let denominator = 0;
    const numberDays = getDaysInMonth(startDate);
    for (let i = 0; i < numberDays; i += 1) {
      const taskDate = add(startDate, { days: i });
      const dayStatus = TaskHelper.dayStatus(task, taskDate);
      if (['completed'].includes(dayStatus)) numerator += 1;
      if (['completed', 'incomplete'].includes(dayStatus)) denominator += 1;
    }
    return { numerator, denominator };
  },
  monthlyGoal: (tasks: Tasks, startDate: Date) => {
    let numerator = 0;
    let denominator = 0;
    Object.values(tasks).forEach((task) => {
      const stats = TaskHelper.monthlyStats(task, startDate);
      numerator += stats.numerator;
      denominator += stats.denominator;
    });
    return denominator ? numerator / denominator : 0;
  },
  monthlyGoalPercentage: (tasks: Tasks, startDate: Date) => {
    return Math.round(TaskHelper.monthlyGoal(tasks, startDate) * 100);
  },
  yearlyStats: (task: Task, startDate: Date) => {
    let numerator = 0;
    let denominator = 0;
    // TODO: This is buggy when it crosses years.
    const numberDays = getDaysInYear(startDate);
    for (let i = 0; i <= numberDays; i += 1) {
      const taskDate = add(startDate, { days: i });
      const dayStatus = TaskHelper.dayStatus(task, taskDate);
      if (['completed'].includes(dayStatus)) numerator += 1;
      if (['completed', 'incomplete'].includes(dayStatus)) denominator += 1;
    }
    return { numerator, denominator };
  },
  yearlyGoal: (tasks: Tasks, startDate: Date) => {
    let numerator = 0;
    let denominator = 0;
    Object.values(tasks).forEach((task) => {
      const stats = TaskHelper.yearlyStats(task, startDate);
      numerator += stats.numerator;
      denominator += stats.denominator;
    });
    return denominator ? numerator / denominator : 0;
  },
  alltimeStats: (task: Task) => {
    let numerator = 0;
    let denominator = 0;
    const taskStartingDate = task.dateCreated.toDate();
    let taskDate = startOfDay(taskStartingDate);
    for (let i = 1; isBefore(taskDate, new Date()); i += 1) {
      const dayStatus = TaskHelper.dayStatus(task, taskDate);
      if (['completed'].includes(dayStatus)) numerator += 1;
      if (['completed', 'incomplete'].includes(dayStatus)) denominator += 1;
      taskDate = add(taskDate, { days: 1 });
    }
    return { numerator, denominator };
  },
  alltimeGoal: (tasks: Tasks) => {
    let numerator = 0;
    let denominator = 0;
    Object.values(tasks).forEach((task) => {
      const stats = TaskHelper.alltimeStats(task);
      numerator += stats.numerator;
      denominator += stats.denominator;
    });
    return denominator ? numerator / denominator : 0;
  },
  allTimeGoalPercentage: (tasks: Tasks): number => {
    return Math.round(TaskHelper.alltimeGoal(tasks) * 100);
  },
};

export default TaskHelper;
