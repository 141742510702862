import React, { useContext } from 'react';

import { DashboardContext } from '../context';
import plus from '../assets/images/plus.svg';
import '../styles/DateRangeSelector.scss';

const DateRangeSelector = () => {
  const { dateRange, setDateRange, toggleAddTaskForm } = useContext(DashboardContext);
  return (
    <div className="date-range-selector__container">
      <div className="date-range-selector">
        <div className={'date-range-selector__highlight date-range-selector__highlight--' + dateRange}></div>
        <ul>
          <li onClick={() => setDateRange('week')} className={dateRange === 'week' ? 'active' : ''}>
            Week
          </li>
          <li onClick={() => setDateRange('month')} className={dateRange === 'month' ? 'active' : ''}>
            Month
          </li>
          <li onClick={() => setDateRange('year')} className={dateRange === 'year' ? 'active' : ''}>
            Year
          </li>
          <li onClick={() => setDateRange('alltime')} className={dateRange === 'alltime' ? 'active' : ''}>
            All Time
          </li>
        </ul>
      </div>
      <button className="whiteButton" onClick={toggleAddTaskForm}>
        <img src={plus} alt="Icon of a plus sign" /> Add Habit
      </button>
    </div>
  );
};

export default DateRangeSelector;
