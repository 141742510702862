import { useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import colors from '../config/colors';
import apiClient from '../services/apiClient';

export const useTaskName = (initialState: string) => {
  const [taskName, setTaskName] = useState<string>(initialState);
  const [taskNameError, setTaskNameError] = useState<boolean>(false);
  const handleChangeTaskName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTaskName(e.target.value);
    if (e.target.value.length === 0) setTaskNameError(true);
    else setTaskNameError(false);
  };
  return {
    taskName,
    setTaskName,
    taskNameError,
    setTaskNameError,
    handleChangeTaskName,
  };
};

export const useToDoNotToDo = (initialState: boolean) => {
  const [notToDo, setNotToDo] = useState(initialState);
  return { notToDo, setNotToDo };
};

export const useWeeklyFrequency = (initialState: WeeklyFrequency | null) => {
  const [weeklyFrequency, setWeeklyFrequency] = useState(
    initialState || { 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false }
  );
  const [weeklyFrequencyError, setWeeklyFrequencyError] = useState<boolean>(false);
  const everyDayFrequency = () => {
    return [0, 1, 2, 3, 4, 5, 6].reduce((accumulator, value) => {
      return accumulator && weeklyFrequency[value];
    }, true);
  };
  const weekdayFrequency = () => {
    const weekDaysTrue = [1, 2, 3, 4, 5].reduce((accumulator, value) => {
      return accumulator && weeklyFrequency[value];
    }, true);
    const weekendsFalse = [0, 6].reduce((accumulator, value) => {
      return accumulator && !weeklyFrequency[value];
    }, true);
    return weekDaysTrue && weekendsFalse;
  };
  const handleFrequencyChange = (day: number) => {
    let stateCopy = { ...weeklyFrequency };
    stateCopy[day] = !weeklyFrequency[day];
    setWeeklyFrequency(stateCopy);
    if (Object.values(stateCopy).includes(true)) {
      setWeeklyFrequencyError(false);
    } else {
      setWeeklyFrequencyError(true);
    }
  };
  const handleWeekdayFrequency = () => {
    if (weekdayFrequency()) {
      setWeeklyFrequency({ 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false });
    } else {
      setWeeklyFrequency({ 0: false, 1: true, 2: true, 3: true, 4: true, 5: true, 6: false });
    }
    setWeeklyFrequencyError(false);
  };
  const handleEverydayFrequency = () => {
    if (everyDayFrequency()) {
      setWeeklyFrequency({ 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false });
    } else {
      setWeeklyFrequency({ 0: true, 1: true, 2: true, 3: true, 4: true, 5: true, 6: true });
    }
    setWeeklyFrequencyError(false);
  };
  return {
    weeklyFrequency,
    setWeeklyFrequency,
    weeklyFrequencyError,
    setWeeklyFrequencyError,
    handleFrequencyChange,
    weekdayFrequency,
    handleWeekdayFrequency,
    everyDayFrequency,
    handleEverydayFrequency,
  };
};

export const useColor = (initialState: Color | null) => {
  const [taskColor, setTaskColor] = useState<Color>(initialState || { value: '#ffcd45', label: 'Yellow' });
  const taskColorValue = taskColor.value;
  const colorOptions = colors;
  const randomizeColor = (existingTasks?: Tasks | null) => {
    const existingColors = existingTasks ? Object.values(existingTasks).map((task) => task.color.value) : [];
    const filteredColorOptions = colorOptions.filter((color) => !existingColors.includes(color.value));
    if (filteredColorOptions.length > 0) {
      setTaskColor(filteredColorOptions[Math.floor(Math.random() * filteredColorOptions.length)]);
    } else {
      setTaskColor(colorOptions[Math.floor(Math.random() * colorOptions.length)]);
    }
  };
  return {
    taskColor,
    taskColorValue,
    setTaskColor,
    randomizeColor,
  };
};

export const handleAddTask = async (task: Task, uid: string) => {
  const res = await firebase
    .firestore()
    .collection('Tasks')
    .add({ ...task, user: uid });
  firebase.analytics().logEvent('habit_created', {
    uid,
    name: task.name,
    taskId: res.id,
  });
};

export const handleEditTask = (task: Task, taskId: string) => {
  apiClient.editTask(taskId, task);
  firebase.analytics().logEvent('habit_edited', {
    uid: task.user,
    name: task.name,
    taskId,
  });
};
