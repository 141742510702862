import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import axios from 'axios';

import { UserContext } from './context';
import LoggedInLayout from './layouts/LoggedInLayout';
import useSubscriptionState from './hooks/useSubscriptionState';
import { forceSWupdate } from './serviceWorker';

import './styles/Reset.scss';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Onboarding from './sections/Onboarding';
import Index from './pages/Index';
import SignUp from './pages/SignUp';
import Settings from './pages/Settings';
import LoadingPage from './components/LoadingPage';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Pricing from './pages/Pricing';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import './styles/Styles.scss';
import './styles/App.scss';
import './styles/Components.scss';
import ScrollToTop from './components/ScrollToTop';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
let unsubscribeUserSettings: any = null;
let unsubscribeUserTasks: any = null;

const App = () => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [userSettings, setUserSettings] = useState<UserSettings | null>(null);
  const [tasks, setTasks] = useState<Tasks | null>(null);
  const [signedIn, setSignedIn] = useState<boolean | null>(null);
  const { subscriptionState } = useSubscriptionState(userSettings);

  const handleSignIn = () => {
    const { currentUser } = firebase.auth();
    if (currentUser) {
      setUser(currentUser);
      setSignedIn(true);
      localStorage?.setItem('ConfettiUser', currentUser.uid);
    }
  };
  const handleSignOut = () => {
    unsubscribeUserSettings();
    unsubscribeUserTasks();
    setUser(null);
    localStorage?.removeItem('ConfettiUser');
    firebase.auth().signOut();
  };
  const updateSubscriptionData = async () => {
    const firebaseToken = await firebase.auth().currentUser!.getIdToken();
    await axios.post('/subscription/status', { firebaseToken });
  };
  const checkServiceWorker = () => {
    const fetchedConfettiVersion = localStorage?.getItem('Confetti');
    if (!fetchedConfettiVersion) return;
    const confettiVersion = JSON.parse(fetchedConfettiVersion);
    if (!confettiVersion || confettiVersion !== '3.1.0') {
      forceSWupdate();
      localStorage?.setItem('Confetti', JSON.stringify({ version: '3.1.0' }));
    }
  };

  useEffect(() => {
    const handleUserDataUpdate = (snapshots: firebase.firestore.QuerySnapshot) => {
      if (!snapshots.empty) {
        const fetchedUserSettings = snapshots.docs[0].data();
        setUserSettings(fetchedUserSettings as UserSettings);
        if (fetchedUserSettings.subscriptionId) updateSubscriptionData();
      }
    };
    const handleUserTasksUpdate = (snapshots: firebase.firestore.QuerySnapshot) => {
      if (!snapshots.empty) {
        const fetchedTasks: Tasks = {};
        snapshots.forEach((snapshot) => {
          const data = snapshot.data();
          fetchedTasks[snapshot.id] = { ...data, id: snapshot.id } as Task;
        });
        setTasks(fetchedTasks);
      } else setTasks({});
    };
    checkServiceWorker();
    firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        unsubscribeUserSettings = firebase
          .firestore()
          .collection('userSettings')
          .where('user', '==', authUser.uid)
          .onSnapshot(handleUserDataUpdate);
        unsubscribeUserTasks = firebase
          .firestore()
          .collection('Tasks')
          .where('user', '==', authUser.uid)
          .onSnapshot(handleUserTasksUpdate);
        handleSignIn();
      } else {
        setSignedIn(false);
      }
    });
  }, []);

  return (
    <UserContext.Provider value={{ handleSignIn, handleSignOut, subscriptionState }}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              if (signedIn && user && userSettings && tasks && !userSettings.onboarded)
                return (
                  <LoggedInLayout
                    user={user}
                    userSettings={userSettings}
                    tasks={tasks}
                    subscriptionState={subscriptionState}
                  >
                    <Onboarding />
                  </LoggedInLayout>
                );
              if (signedIn && user && userSettings && tasks)
                return (
                  <LoggedInLayout
                    user={user}
                    userSettings={userSettings}
                    tasks={tasks}
                    subscriptionState={subscriptionState}
                  >
                    <Dashboard />
                  </LoggedInLayout>
                );
              if (localStorage?.getItem('ConfettiUser') && signedIn !== false) return <LoadingPage />;
              if (user) return <LoadingPage />;
              return <Index />;
            }}
          />

          <Route
            path="/settings"
            render={() => {
              if (signedIn && user && userSettings && tasks)
                return (
                  <LoggedInLayout
                    user={user}
                    userSettings={userSettings}
                    tasks={tasks}
                    subscriptionState={subscriptionState}
                  >
                    <Settings />
                  </LoggedInLayout>
                );
              if (signedIn === false) return <Redirect to="/" />;
              return <LoadingPage />;
            }}
          />

          <Route
            path="/login"
            render={({ location }) => {
              if (signedIn) return <Redirect to="/" />;
              return <Login handleSignIn={handleSignIn} location={location} />;
            }}
          />

          <Route
            path="/signup"
            render={() => {
              if (signedIn) return <Redirect to="/" />;
              return <SignUp handleSignIn={handleSignIn} />;
            }}
          />

          <Route path="/reset_password" render={() => <ResetPassword />} />
          <Route path="/forgot_password" render={() => <ForgotPassword />} />
          <Route path="/privacy" render={PrivacyPolicy} />
          <Route path="/terms" render={Terms} />
          <Route path="/pricing" render={() => <Pricing />} />
        </Switch>
      </Router>
    </UserContext.Provider>
  );
};

export default App;
