import React, { useState, useContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { LoggedInContext, UserContext } from '../context';
import OnboardingBedtime from '../sections/OnboardingBedtime';
import OnboardingAddTask from '../sections/OnboardingAddTask';
import onboardingWelcomeImage from '../assets/images/onboardingWelcome.png';
import '../styles/Onboarding.scss';

export default function Onboarding() {
  const { handleSignIn } = useContext(UserContext);
  const { user, userSettings } = useContext(LoggedInContext);
  const [step, setStep] = useState<number>(0);

  const renderIntroduction = () => (
    <div className="onboarding__step0">
      <img src={onboardingWelcomeImage} alt="Welcome!" />
      <h1>Welcome{user!.displayName ? ` ${user!.displayName.split(' ')[0]}!` : `!`}</h1>
      <p>Before you start, we just have a few questions...</p>
      <div className="onboarding__buttons">
        <button className="blueButton" onClick={() => setStep(1)}>
          Begin
        </button>
      </div>
    </div>
  );

  const setOnboardingComplete = () => {
    firebase.firestore().collection('userSettings').doc(userSettings.user).update({ onboarded: true });
    handleSignIn();
  };

  return (
    <div className="onboarding">
      {step === 0 ? (
        renderIntroduction()
      ) : step === 1 || step === 2 ? (
        <OnboardingAddTask step={step} setStep={setStep} userSettings={userSettings} />
      ) : step === 3 ? (
        <OnboardingBedtime userSettings={userSettings} nextStep={setOnboardingComplete} />
      ) : null}
    </div>
  );
}
