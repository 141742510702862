import React from 'react';
import '../styles/DashboardProgressBar.scss';

interface DashboardProgressBarProps {
  task: Task;
  numerator: number;
  denominator: number;
  mounted: boolean;
  text?: string;
}

const DashboardProgressBar = ({ task, numerator, denominator, mounted, text = '' }: DashboardProgressBarProps) => {
  const { name, notToDo, color } = task;
  return (
    <div className="dashboardProgressBar">
      <div className="dashboardProgressBar__heading">
        <div
          className={notToDo ? 'color-indicator-cross' : 'color-indicator'}
          style={{ backgroundColor: color.value }}
        />
        <span className="dashboardProgressBar__name">{name}</span>
      </div>

      <div className="dashboardProgressBar__bar">
        <span>{text}</span>
        <div
          className="dashboardProgressBar__barComplete"
          style={{
            backgroundColor: color.value,
            width: mounted && denominator ? `${(numerator / denominator) * 100}%` : 0,
          }}
        />
      </div>
      <div className="dashboardProgressBar__end">
        {numerator} / {denominator}
      </div>
    </div>
  );
};

export default DashboardProgressBar;
