import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';

const apiClient = {
  getPrices: async () => {
    const res = await axios.get('/subscription/prices');
    return res;
  },
  getRenewPrice: async () => {
    const firebaseToken = await firebase.auth().currentUser?.getIdToken();
    if (firebaseToken) {
      const request = { firebaseToken };
      const res = await axios.post('/subscription/renew_price/', request);
      return res;
    }
    return { data: { renewPrice: 0 } };
  },
  archiveTask: async (taskId: string) => {
    const firebaseToken = await firebase.auth().currentUser?.getIdToken();
    if (firebaseToken) {
      const request: ArchiveHabitRequest = { firebaseToken, taskId };
      await axios.post('/habit/archive', request);
    }
  },
  unarchiveTask: async (taskId: string) => {
    const firebaseToken = await firebase.auth().currentUser?.getIdToken();
    if (firebaseToken) {
      const request: UnarchiveHabitRequest = { firebaseToken, taskId };
      await axios.post('/habit/unarchive', request);
    }
  },
  editTask: async (taskId: string, task: Partial<Task>) => {
    const firebaseToken = await firebase.auth().currentUser?.getIdToken();
    if (firebaseToken) {
      const request: EditHabitRequest = { firebaseToken, taskId, task };
      await axios.post('/habit/edit', request);
    }
  },
};

export default apiClient;
