import React from 'react';
import { Link } from 'react-router-dom';

import logoWhite from '../assets/images/logo-white.svg';
import '../styles/Header.scss';

const HeaderBlue = () => (
  <header className="header">
    <div className="header__container">
      <Link to="/">
        <img src={logoWhite} alt="Confetti's logo" />
      </Link>
      <div className="header__links">
        <Link to="/pricing">
          <button className="header --mobileHidden blueButton">Pricing</button>
        </Link>
        <Link to="/login">
          <button className="header blueButton">Sign In</button>
        </Link>
        <Link to="/signup">
          <button className="header --mobileHidden whiteButton">Try for Free</button>
        </Link>
      </div>
    </div>
  </header>
);

export default HeaderBlue;
