import React from 'react';

import TaskHelper from '../services/TaskHelper';
import DashboardProgressBar from './DashboardProgressBar';

interface WeekSummaryProps {
  tasks: Tasks;
  weekStart: Date;
  mounted: boolean;
}

const WeekSummary = ({ tasks, weekStart, mounted }: WeekSummaryProps) => (
  <div className="week__main week__summary">
    {TaskHelper.orderEntries(tasks).map(([taskId, task]) => {
      const { numerator, denominator } = TaskHelper.weeklyStats(task, weekStart);
      return (
        <DashboardProgressBar
          key={taskId}
          task={task}
          numerator={numerator}
          denominator={denominator}
          mounted={mounted}
        />
      );
    })}
  </div>
);

export default WeekSummary;
