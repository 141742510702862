import React, { useState, useContext } from 'react';
import { LoggedInContext } from '../context';
import '../styles/HabitLimits.scss';

interface HabitLimitsProps {
  hasPopup?: boolean;
  dark?: boolean;
}

const HabitLimits = ({ hasPopup = false, dark = false }: HabitLimitsProps) => {
  const [showPopup, setShowPopup] = useState(false);
  const { tasks, subscriptionState, togglePaymentModal } = useContext(LoggedInContext);

  const filteredTasks = Object.values(tasks).filter((task) => !task.dateInactive);
  const numberActiveTasks = filteredTasks ? Math.min(Object.values(filteredTasks).length, 3) : 0;

  if (subscriptionState === 'SUBSCRIPTION_INACTIVE')
    return (
      <div
        className="habitLimits"
        data-dark={dark}
        onMouseOver={() => setShowPopup(true)}
        onMouseLeave={() => setShowPopup(false)}
      >
        {hasPopup && showPopup && (
          <div className="habitLimits__popup">
            Upgrade to get <br />
            Unlimited Habits
            <button type="button" className="blueButton" onClick={togglePaymentModal}>
              Upgrade Plan
            </button>
          </div>
        )}
        <div className="habitLimits__indicators">
          {Array.from({ length: numberActiveTasks }).map((_, i) => (
            <div className="habitLimits__indicator" data-active={false} key={`${i}-inactive`} />
          ))}
          {Array.from({ length: 3 - numberActiveTasks }).map((_, i) => (
            <div className="habitLimits__indicator" data-active key={`${i}-active`} />
          ))}
        </div>
        <span>
          {3 - numberActiveTasks} Free Habit{numberActiveTasks !== 2 && 's'} Left
        </span>
      </div>
    );
  return null;
};

export default HabitLimits;
