import React, { useContext } from 'react';

import TaskHelper from '../services/TaskHelper';
import { LoggedInContext } from '../context';
import DashboardProgressBar from './DashboardProgressBar';
import { add } from 'date-fns';

interface MonthSummaryProps {
  monthStart: Date;
  mounted: boolean;
}

const MonthSummary = ({ monthStart, mounted }: MonthSummaryProps) => {
  const { tasks } = useContext(LoggedInContext);
  const filteredTasks = TaskHelper.filter(tasks, monthStart, add(monthStart, { months: 1 }));
  return (
    <div className="month__main">
      {TaskHelper.orderEntries(filteredTasks).map(([taskId, task]) => {
        const { numerator, denominator } = TaskHelper.monthlyStats(task, monthStart);
        return (
          <DashboardProgressBar
            key={taskId}
            task={task}
            numerator={numerator}
            denominator={denominator}
            mounted={mounted}
          />
        );
      })}
    </div>
  );
};

export default MonthSummary;
