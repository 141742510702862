import { useState, Dispatch } from 'react';
import { startOfISOWeek, sub, add } from 'date-fns';

export interface WeekNavigation {
  weekStart: Date;
  setWeekStart: Dispatch<Date>;
  lastWeek: Date;
  handleLastWeek: () => void;
  currentWeek: Date;
  handleCurrentWeek: () => void;
  nextWeek: Date;
  handleNextWeek: () => void;
}

const useWeekNavigation = (): WeekNavigation => {
  const currentWeek = startOfISOWeek(new Date());
  const [weekStart, setWeekStart] = useState(currentWeek);
  const handleCurrentWeek = () => setWeekStart(currentWeek);
  const lastWeek = sub(new Date(weekStart), { weeks: 1 });
  const handleLastWeek = () => setWeekStart(lastWeek);
  const nextWeek = add(new Date(weekStart), { weeks: 1 });
  const handleNextWeek = () => setWeekStart(nextWeek);

  return {
    weekStart,
    setWeekStart,
    lastWeek,
    handleLastWeek,
    currentWeek,
    handleCurrentWeek,
    nextWeek,
    handleNextWeek,
  };
};

export default useWeekNavigation;
