import React, { useContext } from 'react';
import '../styles/BedtimeForm.scss';
import Lottie from 'react-lottie';
import sleepIcon from '../assets/lottie/sleepIcon.json';
import { useBedtimeHour, useBedtimeMinutes, useBedtimeAMPM, handleBedtimeSubmit } from '../hooks/bedtimeHooks';
import { LoggedInContext } from '../context';

interface BedtimeFormProps {
  toggleBedtimeModal: () => void;
}

const BedtimeForm = ({ toggleBedtimeModal }: BedtimeFormProps) => {
  const { userSettings } = useContext(LoggedInContext);
  const { bedtimeMinutes, handleSetBedtimeMinutes } = useBedtimeMinutes(userSettings.bedtimeMinutes);
  const { bedtimeHour, handleSetBedtimeHour } = useBedtimeHour(userSettings.bedtimeHour);
  const { ampm, setAMPM } = useBedtimeAMPM(userSettings.ampm);

  const handleSubmit = () => {
    handleBedtimeSubmit({
      bedtimeHour: bedtimeHour,
      bedtimeMinutes: bedtimeMinutes,
      ampm: ampm,
      uuid: userSettings.user,
    });
    toggleBedtimeModal();
  };

  return (
    <div
      className="form-modal"
      onClick={(e) => {
        if ((e.target as Element).classList.contains('bedtime-wrapper')) toggleBedtimeModal();
      }}
    >
      <div className="bedtime bedtime-wrapper">
        <div className="bedtime-main">
          <div className="bedtime-top">
            <div className="bedtime-topLeft">
              <Lottie options={{ loop: true, autoplay: true, animationData: sleepIcon }} width={250} height={100} />
            </div>
            <div className="bedtime-topRight">
              <h1>What time do you typically go to bed?</h1>
              <h2>A rough estimate is fine</h2>
            </div>
          </div>
          <div className="bedtime-mid">
            <div>
              <label id="hours">Hr</label>
              <input
                type="number"
                id="bedtimeHour"
                placeholder={'00'}
                value={bedtimeHour}
                onChange={handleSetBedtimeHour}
              />
            </div>

            <div>
              <label id="minutes">Min</label>
              <input
                type="number"
                id="bedtimeMinutes"
                placeholder={'00'}
                value={bedtimeMinutes}
                onChange={handleSetBedtimeMinutes}
                step="5"
              />
            </div>

            <div className="bedtime-ampmToggle">
              <div className={'bedtime-ampmToggleHighlight bedtime-ampmToggleHighlight--' + ampm} />
              <button className={ampm === 'am' ? 'bedtime-ampmToggleButton--active' : ''} onClick={() => setAMPM('am')}>
                AM
              </button>
              <button className={ampm === 'pm' ? 'bedtime-ampmToggleButton--active' : ''} onClick={() => setAMPM('pm')}>
                PM
              </button>
            </div>
          </div>
          <div className="bedtime-buttons">
            <button className="whiteButton" onClick={toggleBedtimeModal}>
              Cancel
            </button>
            <button className="blueButton" onClick={handleSubmit}>
              {bedtimeHour ? 'Edit Bedtime' : 'Add Bedtime'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BedtimeForm;
