import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Link } from 'react-router-dom';
import { Location } from 'history';
import firebase from 'firebase/app';
import 'firebase/auth';
import axios from 'axios';
import qs from 'query-string';

import { handleGoogleSignIn, handleFacebookSignIn } from '../services/signInHelper';
import HeaderBlue from '../components/HeaderBlue';
import Footer from '../components/Footer';
import googleIcon from '../assets/images/googleIcon.svg';
import facebookIcon from '../assets/images/facebookIcon.svg';
// import loginApple from '../assets/images/loginApple.svg';
import '../styles/Login.scss';

interface LoginProps {
  location: Location;
  handleSignIn: () => void;
}

const Login = ({ location, handleSignIn }: LoginProps) => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean | undefined>();
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean | undefined>();
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');

  useEffect(() => {
    const handleEmailVerification = async (token: string, uid: string) => {
      const response = await axios.post(`/email/verify_email`, { token, uid });
      if (response.data.success) {
        setError('');
        setSuccess('Your email has been confirmed!');
      } else {
        setError('Your email has not been confirmed.');
      }
    };
    const { verify, user } = qs.parse(location.search);
    if (!!verify && !Array.isArray(verify) && user && !Array.isArray(user)) handleEmailVerification(verify, user);
  }, [location]);

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value.length >= 6) setPasswordError(false);
    else setPasswordError(true);
  };
  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (e.target.value.match(reg)) setEmailError(false);
    else setEmailError(true);
  };
  const handleSubmit = (e: FormEvent) => {
    if (passwordError || emailError) return;
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => handleSignIn())
      .catch((error) => handleError(error));
  };
  const handleError = (error: firebase.FirebaseError) => {
    setError(error.message);
    setSuccess('');
  };

  return (
    <>
      <HeaderBlue />
      <div className="login__wrapper">
        <div className="login__container">
          <h1>Welcome back!</h1>
          {success && <p className="login__success">{success}</p>}
          {error && <p className="login__error">{error}</p>}
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              className={
                emailError === undefined ? '' : emailError === true ? 'login__input--error' : 'login__input--valid'
              }
              placeholder="Email"
              name="email"
              value={email}
              onChange={handleChangeEmail}
            />
            <input
              type="password"
              className={
                passwordError === undefined
                  ? ''
                  : passwordError === true
                  ? 'login__input--error'
                  : 'login__input--valid'
              }
              placeholder="Password"
              name="password"
              value={password}
              onChange={handleChangePassword}
            />

            {emailError === false && passwordError === false ? (
              <button className="login__submitButton" onClick={handleSubmit}>
                Sign In
              </button>
            ) : (
              <button className="login__submitButton login__submitButton--inactive">Sign In</button>
            )}
          </form>

          <Link to="/forgot_password" className="login__forgotPassword">
            Forgot your password?
          </Link>
          <div className="login__separator">
            <small className="login__signUpOr">OR</small>
            <hr />
          </div>

          <button
            className="login__googleButton"
            onClick={() => handleGoogleSignIn({ success: handleSignIn, error: handleError })}
          >
            <img src={googleIcon} alt="Google Icon" />
            Sign In with Google
          </button>
          <button
            className="login__facebookButton"
            onClick={() => handleFacebookSignIn({ success: handleSignIn, error: handleError })}
          >
            <img src={facebookIcon} alt="Facebook Icon" />
            Sign In with Facebook
          </button>
          {/* <button
            className="login__appleButton"
            onClick={() => handleAppleSignIn({ success: handleSignIn, error: handleError })}
          >
            <img src={loginApple} alt="Apple Icon" />
            Sign In with Apple
          </button> */}

          <h2>
            Don't have an account? <Link to="/signup">Sign Up</Link>
          </h2>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
