import React from 'react';
import exitSmall from '../assets/images/exitSmall.svg';

interface AddTaskFormSuccessProps {
  lastAddedTask: Task | null;
  toggleLastAddedTask: () => void;
}

const AddTaskFormSuccess = ({ lastAddedTask, toggleLastAddedTask }: AddTaskFormSuccessProps) => {
  if (lastAddedTask) {
    return (
      <div className="task-form__addSuccess">
        <div
          className={lastAddedTask.notToDo ? 'color-indicator-cross' : 'color-indicator'}
          style={{ backgroundColor: lastAddedTask.color.value }}
        />
        <p>
          <span>{lastAddedTask.name}</span> has been added successfully!
        </p>
        <img src={exitSmall} onClick={toggleLastAddedTask} alt="Exit Icon" />
      </div>
    );
  } else {
    return <div className="task-form__addSuccess"></div>;
  }
};

export default AddTaskFormSuccess;
