const colors = [
  { value: '#ffcd45', label: 'Yellow' },
  { value: '#9c5cff', label: 'Purple' },
  { value: '#07dfe0', label: 'Turquoise' },
  { value: '#ffa031', label: 'Orange' },
  { value: '#3798fa', label: 'Blue' },
  { value: '#18de74', label: 'Green' },
  { value: '#ff5858', label: 'Red' },
  { value: '#33c0ff', label: 'Sky' },
  { value: '#fd4ec7', label: 'Pink' },
  { value: '#4368ff', label: 'Navy' },
];
export default colors;
