import React, { useContext, useEffect } from 'react';
import '../styles/ViewSelector.scss';
import progressIcon from '../assets/images/progressIcon.svg';
import chartIcon from '../assets/images/chartIcon.svg';
import { DashboardContext } from '../context';

interface ViewSelectorProps {
  refreshState: () => void;
  summaryViewOnly?: boolean;
}

const ViewSelector = ({ summaryViewOnly = false, refreshState }: ViewSelectorProps) => {
  const { summaryView, setSummaryView } = useContext(DashboardContext);
  const toggleView = () => {
    if (summaryView) setSummaryView(false);
    else {
      setSummaryView(true);
      refreshState();
    }
  };

  useEffect(() => {
    if (summaryViewOnly) setSummaryView(true);
  }, [summaryViewOnly, setSummaryView]);

  const viewState = summaryViewOnly ? 'full' : summaryView ? 'summary' : 'detailed';
  return (
    <div className="viewSelector">
      <div className="viewSelector__toggleHighlight" aria-label={viewState} />

      <button aria-pressed={summaryView} onClick={toggleView}>
        <img src={progressIcon} alt="Progress" />
      </button>

      {!summaryViewOnly && (
        <button aria-pressed={!summaryView} onClick={toggleView}>
          <img src={chartIcon} alt="Chart" />
        </button>
      )}
    </div>
  );
};

export default ViewSelector;
