import React from 'react';
import { CSSTransition } from 'react-transition-group';

import '../styles/Notification.scss';

interface NotificationProps {
  notification: string | null;
  notificationTask: Task | null;
}

const Notification = ({ notification, notificationTask }: NotificationProps) => (
  <CSSTransition in={!!notification} timeout={500} classNames="header__notification" mountOnEnter unmountOnExit>
    <div className="header__notification">
      <div className="header__notificationContainer">
        {notificationTask && (
          <div
            className={notificationTask.notToDo ? 'color-indicator-cross' : 'color-indicator'}
            style={{ backgroundColor: notificationTask.color.value }}
          />
        )}
        <p>{notification}</p>
      </div>
    </div>
  </CSSTransition>
);

export default Notification;
