import React, { useContext } from 'react';
import { isBefore, isSameDay } from 'date-fns';

import TaskHelper from '../services/TaskHelper';
import { DashboardContext, LoggedInContext } from '../context';

interface MonthPopupProps {
  taskDate: Date;
  popupActive: Date | null;
}

const MonthPopup = ({ taskDate, popupActive }: MonthPopupProps) => {
  const { tasks } = useContext(LoggedInContext);
  const { toggleTask } = useContext(DashboardContext);

  if (isBefore(new Date(), taskDate)) return null;
  const completedTasks = TaskHelper.orderEntries(tasks).filter(
    ([_taskId, task]) => TaskHelper.dayStatus(task, taskDate) === 'completed'
  );
  const incompleteTasks = TaskHelper.orderEntries(tasks).filter(
    ([_taskId, task]) => TaskHelper.dayStatus(task, taskDate) === 'incomplete'
  );
  return (
    <div className="month__calendarItem__popup" data-active={popupActive && isSameDay(popupActive, taskDate)}>
      {completedTasks.map(([taskId, task]) => (
        <div
          key={taskId}
          className="month__calendarItem__popupRow"
          onClick={(e) => toggleTask(task, taskId, taskDate, e)}
        >
          <div className="month__calendarItem__popupRowColor" style={{ backgroundColor: task.color.value }} />
          <p>{task.name}</p>
        </div>
      ))}
      {completedTasks.length > 0 && incompleteTasks.length > 0 && <hr />}
      {incompleteTasks.map(([taskId, task]) => (
        <div
          key={taskId}
          className="month__calendarItem__popupRow"
          onClick={(e) => toggleTask(task, taskId, taskDate, e)}
          data-inactive
        >
          <div className="month__calendarItem__popupRowColor" data-inactive />
          <p>{task.name}</p>
        </div>
      ))}
    </div>
  );
};

export default MonthPopup;
