import React, { useState, useEffect } from 'react';

import habitsIcon from '../assets/images/tabBar-habits.svg';
import progressIcon from '../assets/images/tabBar-progress.svg';
import '../styles/DashboardMobileTabBar.scss';

const DashboardMobileTabBar = () => {
  const [active, setActive] = useState('Habits');

  useEffect(() => {
    const $container = document.querySelector('.dashboard__main');
    if ($container) {
      let lastScrollPosition = 0;
      const handleScroll = (container: any) => {
        if (container.scrollLeft > lastScrollPosition) {
          setActive('Progress');
        } else if (container.scrollLeft < lastScrollPosition) {
          setActive('Habits');
        }
        lastScrollPosition = container.scrollLeft;
      };
      $container.addEventListener('scroll', handleScroll);
      return () => $container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleScrollRight = () => {
    const $container = document.querySelector('.dashboard__main');
    if ($container) $container.scroll({ left: $container.clientWidth, behavior: 'smooth' });
  };

  const handleScrollLeft = () => {
    const $container = document.querySelector('.dashboard__main');
    if ($container) $container.scroll({ left: 0, behavior: 'smooth' });
  };

  return (
    <div className="mobile-only dashboardMobileTabBar">
      <button
        type="button"
        className="dashboardMobileTabBar__button"
        onClick={handleScrollLeft}
        data-active={active === 'Habits'}
      >
        <img src={habitsIcon} alt="An Icon for Habits" />
        Habits
      </button>
      <button
        type="button"
        className="dashboardMobileTabBar__button"
        onClick={handleScrollRight}
        data-active={active === 'Progress'}
      >
        <img src={progressIcon} alt="An Icon for Progress" />
        Progress
      </button>
    </div>
  );
};
export default DashboardMobileTabBar;
