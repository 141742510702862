import React, { useState, useEffect, ReactNode } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';

import { LoggedInContext } from '../context';
import useToggle from '../hooks/useToggle';
import useConfirmUpdateEmail from '../hooks/useConfirmUpdateEmail';
import useHandleEmailVerification from '../hooks/useHandleEmailVerification';
import useHandleUpgrade from '../hooks/useHandleUpgrade';
import HeaderLoggedIn from '../components/HeaderLoggedIn';
import Notification from '../components/Notification';
import EmailConfirmationNotification from '../components/EmailConfirmationNotification';
import PaymentModal from '../components/PaymentModal';

interface LoggedInLayoutProps {
  user: firebase.User;
  userSettings: UserSettings;
  tasks: Tasks;
  subscriptionState: SubscriptionState;
  children: ReactNode;
}

const LoggedInLayout = ({ user, userSettings, tasks, subscriptionState, children }: LoggedInLayoutProps) => {
  const [paymentModalActive, togglePaymentModal] = useToggle(false);
  const [notification, setNotification] = useState<string | null>(null);
  const [notificationTask, setNotificationTask] = useState<Task | null>(null);

  useEffect(() => {
    if (notification)
      setTimeout(() => {
        setNotification(null);
        setNotificationTask(null);
      }, 5000);
  }, [notification]);
  useConfirmUpdateEmail(setNotification);
  useHandleEmailVerification(user, setNotification);
  useHandleUpgrade();

  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZone) firebase.firestore().collection('userSettings').doc(userSettings.user).update({ timeZone });
    firebase.analytics().logEvent('login', { email: user.email });
    // eslint-disable-next-line
  }, []);

  return (
    // @ts-ignore
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
      <LoggedInContext.Provider
        value={{
          user,
          userSettings,
          tasks,
          subscriptionState,
          setNotification,
          togglePaymentModal,
          setNotificationTask,
        }}
      >
        <HeaderLoggedIn />
        <Notification notification={notification} notificationTask={notificationTask} />
        <EmailConfirmationNotification />
        {paymentModalActive && (
          <Elements>
            <PaymentModal />
          </Elements>
        )}
        {children}
      </LoggedInContext.Provider>
    </StripeProvider>
  );
};

export default LoggedInLayout;
