import React from 'react';

import '../styles/SettingsHelp.scss';

const SettingsHelp = () => (
  <div className="settingsHelp">
    <h2>Help & Support</h2>
    <div className="settingsHelp__bottom">
      <p>Have questions, feedback, or a request? We’d love to hear from you.</p>
      <p>
        Feel free to email us at <a href="mailto:help@tryconfetti.io">help@tryconfetti.io</a>.
      </p>
      <a className="blueButton" href="mailto:help@tryconfetti.io">
        Contact Us
      </a>
    </div>
  </div>
);

export default SettingsHelp;
