import React, { useContext } from 'react';
import { add, format } from 'date-fns';

import { DashboardContext } from '../context';
import TaskHelper from '../services/TaskHelper';

interface WeekGridRow {
  task: Task;
  taskId: string;
  weekStart: Date;
}

const WeekGridRow = ({ task, taskId, weekStart }: WeekGridRow) => {
  const { toggleTask } = useContext(DashboardContext);
  const { name, notToDo, color } = task;
  const { numerator, denominator } = TaskHelper.weeklyStats(task, weekStart);
  return (
    <div className="week__gridRow">
      <div className="week__gridRow__heading">
        <div
          className={notToDo ? 'color-indicator-cross' : 'color-indicator'}
          style={{ backgroundColor: color.value }}
        />
        <span className="week__gridRow__name">{name}</span>
      </div>
      <div className="week__gridRow__main">
        {[0, 1, 2, 3, 4, 5, 6].map((dayOffset) => {
          const taskDate = add(weekStart, { days: dayOffset });
          const dayStatus = TaskHelper.dayStatus(task, taskDate);
          if (['notCreated', 'inactive', 'archived'].includes(dayStatus))
            return <div className="week__gridRowItem" key={format(taskDate, 'yyyy-MM-dd')} />;
          if (dayStatus === 'future')
            return <div key={format(taskDate, 'yyyy-MM-dd')} className="week__gridRowItem" data-type="future" />;
          if (dayStatus === 'completed')
            return (
              <button
                type="button"
                aria-label={format(taskDate, 'yyyy-MM-dd')}
                key={format(taskDate, 'yyyy-MM-dd')}
                data-type="completed"
                className="week__gridRowItem"
                style={{ backgroundColor: task.color.value }}
                onClick={(e) => toggleTask(task, taskId, taskDate, e)}
              />
            );
          return (
            <button
              type="button"
              aria-label={format(taskDate, 'yyyy-MM-dd')}
              key={format(taskDate, 'yyyy-MM-dd')}
              data-type="inactive"
              className="week__gridRowItem"
              onClick={(e) => toggleTask(task, taskId, taskDate, e)}
            />
          );
        })}
      </div>
      <div className="week__gridRow__end">
        {numerator} / {denominator}
      </div>
    </div>
  );
};

export default WeekGridRow;
