import React, { Dispatch, useEffect } from 'react';
import Lottie from 'react-lottie';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { useTaskName, useToDoNotToDo, useWeeklyFrequency, useColor, handleAddTask } from '../hooks/addTaskHooks';
import repeatIcon from '../assets/images/repeat.svg';
import plantIcon from '../assets/lottie/plantIcon.json';

interface OnboardingAddTaskPRops {
  step: number;
  setStep: Dispatch<number>;
  userSettings: UserSettings;
}

export default function OnboardingAddTask({ step, setStep, userSettings }: OnboardingAddTaskPRops) {
  const { taskName, setTaskName, taskNameError, setTaskNameError, handleChangeTaskName } = useTaskName('');
  const { notToDo, setNotToDo } = useToDoNotToDo(false);
  const {
    weeklyFrequency,
    weeklyFrequencyError,
    setWeeklyFrequencyError,
    handleFrequencyChange,
    handleEverydayFrequency,
    everyDayFrequency,
    handleWeekdayFrequency,
    weekdayFrequency,
  } = useWeeklyFrequency(null);
  const { taskColor, taskColorValue, setTaskColor, randomizeColor } = useColor(null);

  useEffect(() => randomizeColor(), [randomizeColor]);

  const task = {
    name: taskName,
    notToDo,
    color: taskColor,
    weeklyFrequency,
    history: {},
    dateCreated: firebase.firestore.Timestamp.now(),
  };

  const handleContinue = () => {
    if (taskName !== '') setStep(2);
    else setTaskNameError(true);
  };
  const handleSubmit = () => {
    if (Object.values(weeklyFrequency).includes(true)) {
      handleAddTask(task, userSettings.user);
      setStep(3);
    } else {
      setWeeklyFrequencyError(true);
    }
  };

  const renderPresetTask = (task: Partial<Task>) => {
    return (
      <div
        className="onboarding__presetTask"
        onClick={() => {
          setTaskName(task.name!);
          setNotToDo(task.notToDo!);
          setTaskColor(task.color!);
          setTaskNameError(false);
        }}
      >
        {task.notToDo ? (
          <div className="color-indicator-cross" style={{ backgroundColor: task.color!.value }} />
        ) : (
          <div className="color-indicator" style={{ backgroundColor: task.color!.value }} />
        )}

        <h4>{task.name}</h4>
      </div>
    );
  };

  if (step === 1) {
    return (
      <div className="onboarding__step2">
        <div className="onboarding__step2Left">
          <Lottie options={{ loop: true, autoplay: true, animationData: plantIcon }} width={250} height={200} />
        </div>
        <div className="onboarding__step2Center">
          <h2>Step 1 of 3</h2>
          <h1>Add your first habit</h1>
          <p>Add a habit that you can achieve in bite sized daily amounts</p>

          <div className="onboarding__nameSection">
            <h3>Name this Habit</h3>
            {taskNameError && <h4 className="onboarding_errorText">This is a required field.</h4>}
            <input
              autoFocus
              className="onboarding__nameInput"
              aria-invalid={taskNameError}
              type="text"
              id="name"
              value={taskName}
              placeholder={'Habit name'}
              onChange={handleChangeTaskName}
            />
          </div>

          <div className="onboarding__toDoNotToDoSection">
            <h3>Select the type of habit</h3>
            <div className="onboarding__toDoNotToDoInput" aria-selected={!notToDo} onClick={() => setNotToDo(false)}>
              <div className="color-indicator" style={{ backgroundColor: '#89dea6' }} />
              <h4>To-Do</h4>
              <p>A positive habit that you want to add to your daily routine</p>
            </div>
            <div className="onboarding__toDoNotToDoInput" aria-selected={notToDo} onClick={() => setNotToDo(true)}>
              <div className="color-indicator-cross" style={{ backgroundColor: '#89dea6' }} />
              <h4>Not-To-Do</h4>
              <p>A negative habit that keeps you from achieving your goals</p>
            </div>
          </div>

          <div className="onboarding__buttons">
            <button className="textOnlyButton" onClick={() => setStep(0)}>
              Previous Step
            </button>
            <button className="blueButton" onClick={handleContinue}>
              Continue
            </button>
          </div>
        </div>
        <div className="onboarding__rightSidebar">
          <h3>To-Do</h3>
          {renderPresetTask({ name: 'Yoga', notToDo: false, color: { value: '#18de74', label: 'Green' } })}
          {renderPresetTask({ name: 'Meditate', notToDo: false, color: { value: '#ffa031', label: 'Orange' } })}
          {renderPresetTask({ name: 'Exercise', notToDo: false, color: { value: '#33c0ff', label: 'Blue' } })}
          {renderPresetTask({ name: 'Read', notToDo: false, color: { value: '#ffcd45', label: 'Yellow' } })}
          <h3>Not-To-Do</h3>
          {renderPresetTask({ name: 'Netflix', notToDo: true, color: { value: '#ff5858', label: 'Red' } })}
          {renderPresetTask({ name: 'Reddit', notToDo: true, color: { value: '#9c5cff', label: 'Purple' } })}
          {renderPresetTask({ name: 'Cigarettes', notToDo: true, color: { value: '#4368ff', label: 'Navy' } })}
          {renderPresetTask({ name: 'Alcohol', notToDo: true, color: { value: '#07dfe0', label: 'Turquoise' } })}
        </div>
      </div>
    );
  } else {
    return (
      <div className="onboarding__step3">
        <div className="onboarding__step2Left">
          <Lottie options={{ loop: true, autoplay: true, animationData: plantIcon }} width={250} height={200} />
        </div>
        <div className="onboarding__step2Center">
          <h2>Step 2 of 3</h2>
          <h1>Add your first habit</h1>
          <p>Add a habit that you can achieve in bite sized daily amounts</p>

          <div className="onboarding__addedTaskSection">
            <div
              className={notToDo ? 'color-indicator-cross' : 'color-indicator'}
              style={{ backgroundColor: taskColorValue }}
            />
            <h2>{taskName}</h2>
            <button onClick={() => randomizeColor()}>
              <img src={repeatIcon} alt="Toggle icon" />
              <span>Toggle Color</span>
            </button>
          </div>

          <div className="onboarding__weeklyFrequencySection">
            <div className="onboarding__weeklyFrequencySectionTop">
              <h3>Set Weekly Frequency</h3>
              {weeklyFrequencyError ? <h4 className="onboarding_errorText">This is a required field.</h4> : null}
            </div>
            <p>
              {notToDo
                ? 'What days of the week do you want to avoid this habit?'
                : 'What days of the week do you want to commit to this habit?'}
            </p>
            <div className="onboarding__weeklyFrequencySectionInputs">
              {[1, 2, 3, 4, 5, 6, 0].map((dayNumber) => (
                <label
                  className="checkbox-label"
                  aria-selected={weeklyFrequency[dayNumber]}
                  aria-invalid={weeklyFrequencyError}
                  key={dayNumber}
                >
                  {moment().day(dayNumber).format('ddd')}
                  <input
                    type="checkbox"
                    checked={weeklyFrequency[dayNumber]}
                    onChange={() => handleFrequencyChange(dayNumber)}
                  />
                </label>
              ))}
              <div className="onboarding__weeklyFrequencySectionButtons">
                <button
                  onClick={handleWeekdayFrequency}
                  aria-pressed={weekdayFrequency()}
                  aria-invalid={weeklyFrequencyError}
                >
                  Week days
                </button>
                <button
                  onClick={handleEverydayFrequency}
                  aria-pressed={everyDayFrequency()}
                  aria-invalid={weeklyFrequencyError}
                >
                  Every day
                </button>
              </div>
            </div>
          </div>

          <div className="onboarding__buttons">
            <button className="textOnlyButton" onClick={() => setStep(1)}>
              Previous Step
            </button>
            <button className="blueButton" onClick={handleSubmit}>
              Next Step
            </button>
          </div>
        </div>

        <div className="onboarding__rightSidebar">
          <h3>To-Do</h3>
          {renderPresetTask({ name: 'Yoga', notToDo: false, color: { value: '#18de74', label: 'Green' } })}
          {renderPresetTask({ name: 'Meditate', notToDo: false, color: { value: '#ffa031', label: 'Orange' } })}
          {renderPresetTask({ name: 'Exercise', notToDo: false, color: { value: '#33c0ff', label: 'Blue' } })}
          {renderPresetTask({ name: 'Read', notToDo: false, color: { value: '#ffcd45', label: 'Yellow' } })}
          <h3>Not-To-Do</h3>
          {renderPresetTask({ name: 'Netflix', notToDo: true, color: { value: '#ff5858', label: 'Red' } })}
          {renderPresetTask({ name: 'Reddit', notToDo: true, color: { value: '#9c5cff', label: 'Purple' } })}
          {renderPresetTask({ name: 'Cigarettes', notToDo: true, color: { value: '#4368ff', label: 'Navy' } })}
          {renderPresetTask({ name: 'Alcohol', notToDo: true, color: { value: '#07dfe0', label: 'Turquoise' } })}
        </div>
      </div>
    );
  }
}
