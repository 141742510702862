import React from 'react';

import TaskHelper from '../services/TaskHelper';
import WeekGridRow from './WeekGridRow';

interface WeekGridProps {
  tasks: Tasks;
  weekStart: Date;
}

const WeekGrid = ({ tasks, weekStart }: WeekGridProps) => (
  <div className="week__main">
    <div className="week__gridRow" data-type="heading">
      <div className="week__gridRow__heading" />
      <div className="week__gridRow__main">
        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => {
          return (
            <div className="week__gridRowItem" key={day} data-type="heading">
              {day}
            </div>
          );
        })}
      </div>
      <div className="week__gridRow__end" />
    </div>
    {TaskHelper.orderEntries(tasks).map(([taskId, task]) => {
      return <WeekGridRow key={taskId} taskId={taskId} task={task} weekStart={weekStart} />;
    })}
  </div>
);

export default WeekGrid;
