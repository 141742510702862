import { useEffect, useContext } from 'react';
import axios from 'axios';
import qs from 'query-string';
import firebase from 'firebase/app';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../context';

const useHandleEmailVerification = (currentUser: firebase.User, setNotification: (notification: string) => void) => {
  const { handleSignIn } = useContext(UserContext);
  const location = useLocation();

  useEffect(() => {
    const { verify, user } = qs.parse(location.search);
    const handleEmailVerification = async (token: string, uid: string) => {
      const response = await axios.post(`/email/verify_email`, { token, uid });
      if (response.data.success) {
        setNotification('Your email has been confirmed!');
        handleSignIn();
      } else {
        setNotification('There was a problem with your email confirmation.');
      }
    };
    if (currentUser?.emailVerified) return;
    if (verify && user && !Array.isArray(verify) && !Array.isArray(user)) handleEmailVerification(verify, user);
    // eslint-disable-next-line
  }, [location, currentUser]);
  return null;
};

export default useHandleEmailVerification;
