import React, { useEffect, useState, useContext } from 'react';
import { LoggedInContext } from '../context';
import useTasks from '../hooks/useTasks';

const DayExpired = () => {
  const { tasks, togglePaymentModal } = useContext(LoggedInContext);
  const [selectedHabits, setSelectedHabits] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const { archiveTask } = useTasks();

  useEffect(() => {
    if (selectedHabits.length <= 3) setError(false);
  }, [selectedHabits]);

  const handleToggleHabit = (id: string) => {
    if (selectedHabits.includes(id)) setSelectedHabits(selectedHabits.filter((taskId) => taskId !== id));
    else setSelectedHabits([...selectedHabits, id]);
  };

  const handleContinueTracking = () => {
    if (selectedHabits.length > 3) return setError(true);
    if (selectedHabits.length === 0) return setError(true);
    for (const taskId of Object.keys(tasks)) {
      if (!selectedHabits.includes(taskId) && !tasks[taskId].dateInactive) archiveTask(taskId);
    }
  };

  return (
    <div className="day__main day__expired">
      <h2>Your Confetti Premium Subscription has expired</h2>
      <h3>Select 3 to continue tracking or re-subscribe to keep all your habits</h3>
      {Object.entries(tasks).map(([taskId, task]) => (
        <div
          role="button"
          key={task.id}
          className="day__expiredItem"
          data-selected={selectedHabits.includes(taskId)}
          data-error={error}
          onClick={() => handleToggleHabit(taskId)}
        >
          <div
            className={task.notToDo ? 'color-indicator-cross' : 'color-indicator'}
            style={{ backgroundColor: task.color.value }}
          />
          <p>{task.name}</p>
        </div>
      ))}
      <button type="button" className="day__expiredSubscribeButton" onClick={togglePaymentModal}>
        Re-Subscribe
      </button>
      <button type="button" className="day__expiredContinueButton" onClick={handleContinueTracking}>
        Continue Tracking
      </button>
    </div>
  );
};

export default DayExpired;
