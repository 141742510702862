import React from 'react';
import Lottie from 'react-lottie';
import loading from '../assets/lottie/loading.json';

const LoadingPage = () => (
  <div className="loadingPage">
    <Lottie options={{ loop: true, autoplay: true, animationData: loading }} width={250} height={200} />
  </div>
);

export default LoadingPage;
