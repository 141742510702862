import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { CSSTransition } from 'react-transition-group';
import { Elements } from 'react-stripe-elements';

import { LoggedInContext } from '../context';
import apiClient from '../services/apiClient';

import { useToggle } from '../hooks';
import ChangePlanForm from './ChangePlanForm';
import UpdatePaymentForm from './UpdatePaymentForm';
import creditCardVisa from '../assets/images/creditCards-visa.svg';
import creditCardMC from '../assets/images/creditCards-mc.svg';
import creditCardAmex from '../assets/images/creditCards-amex.svg';
import creditCardGeneric from '../assets/images/creditCards-generic.svg';
import '../styles/SettingsSubscription.scss';

interface SettingsSubscriptionProps {
  toggleCancelSubscriptionModal: () => void;
}

const SettingsSubscription = ({ toggleCancelSubscriptionModal }: SettingsSubscriptionProps) => {
  const { subscriptionState, userSettings, togglePaymentModal } = useContext(LoggedInContext);
  const { subscriptionEnd, subscriptionFrequency, subscriptionCardBrand, subscriptionLast4 } = userSettings;
  const [updatePaymentFormActive, toggleUpdatePaymentForm] = useToggle(false);
  const [changePlanFormActive, toggleChangePlanForm] = useToggle(false);
  const [prices, setPrices] = useState({ month: 0, year: 0 });
  const [renewPrice, setRenewPrice] = useState(0);

  const [subscriptionNotification, setSubscriptionNotification] = useState<string | null>(null);
  useEffect(() => {
    if (subscriptionNotification) setTimeout(() => setSubscriptionNotification(null), 3000);
  }, [subscriptionNotification]);

  useEffect(() => {
    const fetchPrices = async () => {
      const { data: priceData } = await apiClient.getPrices();
      const { data: renewPriceData } = await apiClient.getRenewPrice();
      setPrices(priceData);
      setRenewPrice(renewPriceData.renewPrice);
    };

    fetchPrices();
  }, []);

  const subscriptionType = () => {
    if (subscriptionState.includes('SUBSCRIPTION_ACTIVE') && subscriptionFrequency === 'month')
      return <h3>Confetti Premium - Monthly</h3>;
    if (subscriptionState.includes('SUBSCRIPTION_ACTIVE') && subscriptionFrequency === 'year')
      return <h3>Confetti Premium - Annual</h3>;
    return <h3>Confetti Free</h3>;
  };
  const subscriptionStatusMessage = () => {
    if (subscriptionState === 'SUBSCRIPTION_ACTIVE_RENEW')
      return <h4>{moment(subscriptionEnd!.seconds, 'X').diff(moment(), 'days')} days until subscription renews</h4>;
    if (subscriptionState === 'SUBSCRIPTION_ACTIVE_ENDING')
      return <h4>{moment(subscriptionEnd!.seconds, 'X').diff(moment(), 'days')} days until your subscription ends</h4>;
    return <h4>Free Plan</h4>;
  };
  const subscriptionPrimaryMessage = () => {
    if (subscriptionState === 'SUBSCRIPTION_ACTIVE_RENEW')
      return (
        <>
          <p>
            Your plan will automatically renew on {moment(subscriptionEnd!.seconds, 'X').format('LL')} and you will be
            charged ${(renewPrice / 100).toFixed(2)}
          </p>
          <p>
            You can cancel it anytime. Until expiry you will still have access to all the features of Confetti. We won’t
            charge you afterwards.
          </p>
        </>
      );
    if (subscriptionState === 'SUBSCRIPTION_ACTIVE_ENDING')
      return (
        <>
          <p>
            You have cancelled auto-renew. Your subscription will end on{' '}
            {moment(subscriptionEnd!.seconds, 'X').format('LL')}.
          </p>
          <p>Until then you will still have access to all the features of Confetti. We won’t charge you afterwards.</p>
        </>
      );
    return <p>You do not currently have a premium membership.</p>;
  };
  const subscriptionPaymentInfo = () => {
    const creditCardIcons: { [key: string]: any } = {
      Visa: creditCardVisa,
      MasterCard: creditCardMC,
      'American Express': creditCardAmex,
    };
    if (subscriptionState === 'SUBSCRIPTION_ACTIVE_RENEW')
      return (
        <div className="settingsSubscription__paymentInfo">
          <img
            src={(subscriptionCardBrand && creditCardIcons[subscriptionCardBrand]) || creditCardGeneric}
            alt="Credit Card Icon"
          />
          •••• {subscriptionLast4}
        </div>
      );
    return null;
  };
  const subscriptionButtons = () => {
    if (subscriptionState === 'SUBSCRIPTION_ACTIVE_RENEW')
      return (
        <div className="settingsSubscription__formButtons">
          <button type="button" className="blueButton" onClick={toggleUpdatePaymentForm}>
            Update Payment
          </button>
          <button type="button" className="whiteButton" onClick={toggleChangePlanForm}>
            Change Plan
          </button>
        </div>
      );
    if (subscriptionState === 'SUBSCRIPTION_ACTIVE_ENDING')
      return (
        <div className="settingsSubscription__formButtons">
          <button type="button" className="blueButton" onClick={togglePaymentModal}>
            Renew Subscription
          </button>
        </div>
      );
    return (
      <div className="settingsSubscription__formButtons">
        <button type="button" className="blueButton" onClick={togglePaymentModal}>
          Upgrade Now
        </button>
      </div>
    );
  };

  if (updatePaymentFormActive) {
    return (
      <Elements>
        <UpdatePaymentForm
          setSubscriptionNotification={setSubscriptionNotification}
          toggleUpdatePaymentForm={toggleUpdatePaymentForm}
        />
      </Elements>
    );
  }
  if (changePlanFormActive)
    return (
      <ChangePlanForm
        toggleChangePlanForm={toggleChangePlanForm}
        toggleCancelSubscriptionModal={toggleCancelSubscriptionModal}
        setSubscriptionNotification={setSubscriptionNotification}
        prices={prices}
      />
    );
  return (
    <div className="settingsSubscription">
      <div className="settingsSubscription__top">
        <h2>Subscription</h2>
        {subscriptionStatusMessage()}
      </div>
      <div className="settingsSubscription__row">
        <CSSTransition
          in={!!subscriptionNotification}
          timeout={300}
          classNames="settingsNotification"
          mountOnEnter
          unmountOnExit
        >
          <div className="settingsNotification">{subscriptionNotification}</div>
        </CSSTransition>
        {subscriptionType()}
        {subscriptionPrimaryMessage()}
        {subscriptionPaymentInfo()}
        {subscriptionButtons()}
      </div>
    </div>
  );
};

export default SettingsSubscription;
