import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/auth';

import { handleGoogleSignIn, handleFacebookSignIn } from '../services/signInHelper';
import HeaderBlue from '../components/HeaderBlue';
import Footer from '../components/Footer';
import googleIcon from '../assets/images/googleIcon.svg';
import facebookIcon from '../assets/images/facebookIcon.svg';
// import loginApple from '../assets/images/loginApple.svg';
import '../styles/Login.scss';

interface SignUpProps {
  handleSignIn: () => void;
}

const SignUp = ({ handleSignIn }: SignUpProps) => {
  const [name, setName] = useState<string>('');
  const [nameError, setNameError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>('');
  const [error, setError] = useState<string>('');

  const handleChangeName = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    if (e.target.value.length >= 1) setNameError(false);
    else setNameError(true);
  };
  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value.length >= 6 && e.target.value.length <= 256) setPasswordError(false);
    else setPasswordError(true);
  };
  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (e.target.value.match(reg)) setEmailError(false);
    else setEmailError(true);
  };
  const handleSubmit = async (e: FormEvent) => {
    if (nameError || passwordError || emailError) return;
    e.preventDefault();
    try {
      const response = await firebase.auth().createUserWithEmailAndPassword(email, password);
      response.user!.updateProfile({ displayName: name });
      setError('');
      setSuccess('Please check your email to verify your account.');
      setName('');
      setEmail('');
      setPassword('');
      setNameError(false);
      setEmailError(false);
      setPasswordError(false);
    } catch (err) {
      setError(err.message);
    }
  };
  const handleError = (error: firebase.FirebaseError) => setError(error.message);

  return (
    <>
      <HeaderBlue />
      <div className="login__wrapper">
        <div className="login__container">
          <h1>Welcome!</h1>
          {success && <p className="login__success">{success}</p>}
          {error && <p className="login__error">{error}</p>}
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              className={
                nameError === undefined ? '' : nameError === true ? 'login__input--error' : 'login__input--valid'
              }
              placeholder="Name"
              name="name"
              value={name}
              onChange={handleChangeName}
            />
            <input
              type="email"
              className={
                emailError === undefined ? '' : emailError === true ? 'login__input--error' : 'login__input--valid'
              }
              placeholder="Email"
              name="email"
              value={email}
              onChange={handleChangeEmail}
            />
            <input
              type="password"
              className={
                passwordError === undefined
                  ? ''
                  : passwordError === true
                  ? 'login__input--error'
                  : 'login__input--valid'
              }
              placeholder="Create Password"
              name="password"
              value={password}
              onChange={handleChangePassword}
            ></input>

            {nameError === false && emailError === false && passwordError === false ? (
              <button className="login__submitButton" onClick={handleSubmit}>
                Sign Up
              </button>
            ) : (
              <button className="login__submitButton login__submitButton--inactive">Sign Up</button>
            )}
          </form>

          <div className="login__separator">
            <small className="login__signUpOr">OR</small>
            <hr />
          </div>
          <button
            className="login__googleButton"
            onClick={() => handleGoogleSignIn({ error: handleError, success: handleSignIn })}
          >
            <img src={googleIcon} alt="Google Icon" />
            Sign Up with Google
          </button>
          <button
            className="login__facebookButton"
            onClick={() => handleFacebookSignIn({ error: handleError, success: handleSignIn })}
          >
            <img src={facebookIcon} alt="Facebook Icon" />
            Sign Up with Facebook
          </button>
          {/* <button
            className="login__appleButton"
            onClick={() => handleAppleSignIn({ error: handleError, success: handleSignIn })}
          >
            <img src={loginApple} alt="Apple Icon" />
            Sign Up with Apple
          </button> */}
          <h2>
            Already have an account? <Link to="/login">Sign In</Link>
          </h2>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SignUp;
