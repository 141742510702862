import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import '../styles/Header.scss';
import logo from '../assets/images/logo.svg';
import hamburger from '../assets/images/hamburger.svg';
import LinkedInIcon from '../assets/images/LinkedInIcon.svg';
import twitterIcon from '../assets/images/twitterIcon.svg';

const IndexHeader = () => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  return (
    <>
      <header className="header --white">
        <div className="header__container">
          <Link to="/">
            <img src={logo} alt="Confetti Logo" />
          </Link>
          <div className="header__links">
            <Link to="/pricing">
              <button className="header --mobileHidden textOnlyButton">Pricing</button>
            </Link>
            <Link to="/login">
              <button className="textOnlyButton">Sign In</button>
            </Link>
            <Link to="/signup">
              <button className="header --mobileHidden blueButton">Try for Free</button>
            </Link>
          </div>
          <div className="header__hamburger">
            <button onClick={() => setMenuVisible(true)}>
              <img src={hamburger} alt="Hamburger menu icon" />
            </button>
          </div>
        </div>
      </header>

      <CSSTransition in={isMenuVisible} timeout={300} classNames="header__mobile" mountOnEnter unmountOnExit>
        <div className="header__overlay" onClick={() => setMenuVisible(false)}>
          <div className="header__hamburgerMenu">
            <div className="header__hamburgerMenuLogin">
              <Link to="/login">
                <button className="blueButton">Login</button>
              </Link>
              <Link to="/signup">
                <button className="blueButton">Sign Up</button>
              </Link>
            </div>
            <div className="header__hamburgerMenuSection">
              <a href="#pricing">Pricing</a>
            </div>
            <div className="header__hamburgerMenuSection">
              <a href="https://apps.apple.com/us/app/confetti-habits/id1507853418">iOS App</a>
              <a href="https://play.google.com/store/apps/details?id=com.confettimobile">Android App</a>
              <a href="https://chrome.google.com/webstore/detail/confetti-habit-tracker/hicbenbldlkghoodigniooplopgjjpnh">
                Chrome Extension
              </a>
            </div>
            <div className="header__hamburgerMenuSection">
              <a href="mailto:hello@tryconfetti.io">Email Us</a>
              <Link to="/privacy">Privacy Policy</Link>
              <Link to="/terms">Terms of Service</Link>
              <div className="footer__rightBottom">
                <a href="https://twitter.com/confettihabits" target="_blank" rel="noopener noreferrer">
                  <img src={twitterIcon} alt="Twitter icon" />
                </a>
                <a href="https://www.linkedin.com/company/confettihabits" target="_blank" rel="noopener noreferrer">
                  <img src={LinkedInIcon} alt="LinkedIn icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};

export default IndexHeader;
