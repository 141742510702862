import React from 'react';
import firebase from 'firebase/app';

import SettingsAvatarForm from './SettingsAvatarForm';
import SettingsNameForm from './SettingsNameForm';
import SettingsEmailForm from './SettingsEmailForm';
import SettingsPasswordForm from './SettingsPasswordForm';
import '../styles/SettingsProfile.scss';

interface SettingsProfileProps {
  user: firebase.User;
  userSettings: UserSettings;
  handleSignIn: () => void;
}

const SettingsProfile = ({ user, userSettings, handleSignIn }: SettingsProfileProps) => (
  <div className="settingsProfile">
    <h2>Profile</h2>
    <SettingsAvatarForm
      uid={user.uid!}
      photoURL={user.photoURL!}
      selectedAvatar={userSettings.selectedAvatar}
      handleSignIn={handleSignIn}
    />
    <SettingsNameForm user={user} handleSignIn={handleSignIn} />
    <SettingsEmailForm user={user} />
    <SettingsPasswordForm />
  </div>
);

export default SettingsProfile;
