import React from 'react';

import avatarGreen from '../assets/images/avatarGreen.svg';
import avatarBlue from '../assets/images/avatarBlue.svg';
import avatarPink from '../assets/images/avatarPink.svg';
import avatarPurple from '../assets/images/avatarPurple.svg';
import avatarAqua from '../assets/images/avatarAqua.svg';
import avatarRed from '../assets/images/avatarRed.svg';

interface UserAvatarProps {
  selectedAvatar: AvatarType;
  photoURL?: string | null;
}

const UserAvatar = ({ selectedAvatar, photoURL }: UserAvatarProps) => {
  const avatarImage = {
    green: avatarGreen,
    blue: avatarBlue,
    pink: avatarPink,
    purple: avatarPurple,
    aqua: avatarAqua,
    red: avatarRed,
  };
  const selectedAvatarImage = () => {
    if (selectedAvatar === 'own') return photoURL || avatarBlue;
    if (selectedAvatar) return avatarImage[selectedAvatar] || avatarBlue;
    return avatarBlue;
  };
  return (
    <div className="userAvatar">
      <img src={selectedAvatarImage()} alt={`${selectedAvatar} avatar`} />
    </div>
  );
};

export default UserAvatar;
