import React from 'react';
import leftArrow from '../assets/images/left-arrow.svg';
import leftArrowInactive from '../assets/images/left-arrow-inactive.svg';
import rightArrow from '../assets/images/right-arrow.svg';
import rightArrowInactive from '../assets/images/right-arrow-inactive.svg';
import '../styles/DateSelectors.scss';

interface DateSelectorsProps {
  leftArrowAction?: () => void;
  rightArrowAction?: () => void;
  backInactive?: boolean;
  forwardInactive?: boolean;
}

const DateSelectors = ({ leftArrowAction, rightArrowAction, backInactive, forwardInactive }: DateSelectorsProps) => (
  <div className="dateSelectors">
    <button type="button" data-inactive={backInactive} onClick={backInactive ? undefined : leftArrowAction}>
      <img src={backInactive ? leftArrowInactive : leftArrow} alt="Arrow pointing left" />
    </button>
    <button type="button" data-inactive={forwardInactive} onClick={forwardInactive ? undefined : rightArrowAction}>
      <img src={forwardInactive ? rightArrowInactive : rightArrow} alt="Arrow pointing right" />
    </button>
  </div>
);

export default DateSelectors;
