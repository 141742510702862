import { useState } from 'react';

const useTaskForm = () => {
  const [formEdit, setFormEdit] = useState<string | null>(null);
  const [addTaskFormActive, setAddTaskFormActive] = useState<boolean>(false);
  const toggleAddTaskForm = () => {
    setFormEdit(null);
    setAddTaskFormActive((prev) => !prev);
  };
  const toggleEditTaskForm = (taskId: string) => {
    setFormEdit(taskId);
    setAddTaskFormActive(true);
  };
  return {
    formEdit,
    addTaskFormActive,
    toggleAddTaskForm,
    toggleEditTaskForm,
  };
};

export default useTaskForm;
