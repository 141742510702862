import React, { Dispatch, createContext } from 'react';
import firebase from 'firebase/app';

interface UserContext {
  handleSignIn: () => void;
  handleSignOut: () => void;
  subscriptionState: SubscriptionState | null;
}

interface LoggedInContext {
  user: firebase.User;
  userSettings: UserSettings;
  tasks: Tasks;
  subscriptionState: SubscriptionState;
  setNotification: Dispatch<string | null>;
  setNotificationTask: Dispatch<Task | null>;
  togglePaymentModal: () => void;
}

interface DashboardContext {
  toggleTask: (() => void) | ((task: Task, taskId: string, taskDate: Date, e: React.MouseEvent) => void);
  toggleAddTaskForm: () => void;
  toggleBedtimeModal: () => void;
  toggleEditTaskForm: (taskId: string) => void;
  toggleDeleteTaskModal: (taskId: string | null) => void;
  toggleArchiveTaskModal: (taskId: string | null) => void;
  dateRange: DashboardRange;
  setDateRange: Dispatch<DashboardRange>;
  summaryView: boolean;
  setSummaryView: Dispatch<boolean>;
}

export const UserContext = createContext<UserContext>({} as UserContext);
export const LoggedInContext = createContext<LoggedInContext>({} as LoggedInContext);
export const DashboardContext = createContext<DashboardContext>({} as DashboardContext);
