import React from 'react';
import Lottie from 'react-lottie';

import { useBedtimeHour, useBedtimeMinutes, useBedtimeAMPM, handleBedtimeSubmit } from '../hooks/bedtimeHooks';
import sleepIcon from '../assets/lottie/sleepIcon.json';

interface OnboardingBedtimeProps {
  userSettings: UserSettings;
  nextStep: () => void;
}

export default function OnboardingBedtime({ userSettings, nextStep }: OnboardingBedtimeProps) {
  const { bedtimeMinutes, handleSetBedtimeMinutes } = useBedtimeMinutes(0);
  const { bedtimeHour, handleSetBedtimeHour } = useBedtimeHour(11);
  const { ampm, setAMPM } = useBedtimeAMPM('pm');

  const handleSubmit = () => {
    handleBedtimeSubmit({
      bedtimeHour: bedtimeHour,
      bedtimeMinutes: bedtimeMinutes,
      ampm: ampm,
      uuid: userSettings.user,
    });
    nextStep();
  };

  return (
    <div className="onboarding__step1">
      <div className="onboarding__step1Left">
        <Lottie options={{ loop: true, autoplay: true, animationData: sleepIcon }} width={250} height={200} />
      </div>
      <div className="onboarding__step1Right">
        <h2>Step 3 of 3</h2>
        <h1>What time do you typically go to bed?</h1>
        <p>We’ll use this to help provide context to your day</p>
        <div className="bedtime">
          <div className="bedtime-hourInput">
            <input
              type="number"
              id="bedtimeHour"
              placeholder={'00'}
              value={bedtimeHour}
              onChange={handleSetBedtimeHour}
            />
            <label>:</label>
            <input
              type="number"
              id="bedtimeMinutes"
              placeholder={'00'}
              value={bedtimeMinutes}
              onChange={handleSetBedtimeMinutes}
              step="5"
            />
          </div>
          <div className="bedtime-ampmToggle">
            <div className={'bedtime-ampmToggleHighlight bedtime-ampmToggleHighlight--' + ampm} />
            <button className={ampm === 'am' ? 'bedtime-ampmToggleButton--active' : ''} onClick={() => setAMPM('am')}>
              AM
            </button>
            <button className={ampm === 'pm' ? 'bedtime-ampmToggleButton--active' : ''} onClick={() => setAMPM('pm')}>
              PM
            </button>
          </div>
        </div>
        <div className="onboarding__buttons">
          <button className="blueButton" onClick={handleSubmit}>
            Complete
          </button>
        </div>
      </div>
    </div>
  );
}
